import React, { useState, useEffect } from 'react';
import { View, Text } from 'react-native';
import { Dropdown } from 'react-native-element-dropdown';
import { dropdownStylesNew } from '../styles/DropdownStylesNew';
import { AntDesign } from '@expo/vector-icons';
import { MaterialIcons } from '@expo/vector-icons';
import { count } from 'rxjs';

const DropdownComponentStart = (props: {
    data: any,
    placeholderText: string,
    onItemSelected: (item: any) => void,
    hidden: (boolean),
    startVal?: number,
}) => {
    const [value, setValue] = React.useState(props.startVal ?? null);
    const [isFocus, setIsFocus] = React.useState(false);
       
    useEffect(()=> {
        if(props?.startVal !== 0){
            setValue(props?.startVal)
        }
        else{
            setValue(props?.startVal)
        }
    },[props?.startVal])
    
    return (
        <View style={dropdownStylesNew.container}>
            <Dropdown
                style={[dropdownStylesNew.dropdown, props.hidden ? dropdownStylesNew.hidden : dropdownStylesNew.visible, isFocus && { borderColor: '#003849' }]}
                itemTextStyle={{backgroundColor: "white", color:"black"}}
                label="Check"
                placeholderStyle={dropdownStylesNew.placeholderStyle}
                selectedTextStyle={dropdownStylesNew.selectedTextStyle}
                iconStyle={dropdownStylesNew.iconStyle}
                data={props.data}
                maxHeight={300}
                labelField="label"
                valueField="value"
                placeholder={props.placeholderText}
                searchPlaceholder="Search..."
                value={value}
                onFocus={() => setIsFocus(true)}
                onBlur={() => setIsFocus(false)}
                onChange={item => {
                    setValue(item.value);
                    setIsFocus(false);
                    props.onItemSelected(item)
                }}
                renderRightIcon={() => (
                    <MaterialIcons name="keyboard-arrow-down" size={24} color="white" />
              )}
            />
        </View>
    );
};

export default DropdownComponentStart;