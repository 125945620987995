import { getDateInUtcSeconds } from "../components/common/utils/date-time-conversions";
import { PrepareApiProcedure } from "../components/common/utils/prepare-api-procedure";
import { ChargerAttributes } from "../models/charger-model";
import { fetchDataFromAPI } from "./api-requests";

export const checkinCharger = async (orderId: string) => {
    //check in the charger
    const apiQuery = PrepareApiProcedure(
        `charger/checkin`,
        'POST', 
        'charger', 
        {
            orderId: orderId
        });
    return await fetchDataFromAPI(apiQuery);
}

export const  checkoutCharger = async (orderId: string, email: string) => {
    //Check out the charger using the checkout API
    const d = new Date().getTimezoneOffset();
    const apiQuery = PrepareApiProcedure(`charger/checkout`, 
                                            'POST', 
                                            'charger', 
                                            {
                                                orderId: orderId,
                                                tz_offset: d,
                                                email: email
                                            });
  
    let checkedOut = await fetchDataFromAPI(apiQuery);
    // TODO: Check the response for success here
}
  

// export const getChargerStatus = async (charger: ChargerAttributes) => {
//     try {
//         const apiQuery = PrepareApiProcedure(`charger/getChargerCarData`, 'POST', 'charger', {chargerId: charger.uuid});
//         const chargerStatus = await fetchDataFromAPI(apiQuery);
//         return chargerStatus;
//     } catch (error) {
//         console.log(error);
//     }
// }

export const getActiveChargeData = async (orderId: string) => {
    try {
        const apiQuery = PrepareApiProcedure(`charger/getActiveChargeData`, 'POST', 'charger', {orderId: orderId});
        const chargerStatus = await fetchDataFromAPI(apiQuery);
        return chargerStatus;
    } catch (error) {
        console.log(error);
    }
}

export const validateCharger = async (serialNumber: string) => {
    try {
        const apiQuery = PrepareApiProcedure(`charger/validate/${serialNumber}`, 'GET', 'charger', '');
        const validCharger = await fetchDataFromAPI(apiQuery);
        return validCharger;
    } catch (error) {
        console.log(error);
    }
}

export const getSingleChargerData = async (chargerId: string) => {
    try {
        const apiQuery = PrepareApiProcedure(
            `charger/getSingleCharger`, 
            'POST', 
            'charger', 
            {
                chargerId: chargerId
            });
        const singleCharger = await fetchDataFromAPI(apiQuery);
        return singleCharger[0];
    } catch (error) {
        console.log(error);
    }
}