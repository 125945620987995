import React from 'react';
import { Pressable, View, Text, Image } from 'react-native';
import * as WebBrowser from 'expo-web-browser';

const AppleAuthButton = () => {
  const startAppleAuth = async () => {
    const authUrl = 'https://app.meanderev.co:8803/api/apple/oauth2/init/web';
    const result = await WebBrowser.openAuthSessionAsync(authUrl);

    if (result.type === 'success') {
      console.log("Apple OAuth flow complete.");
    } else {
      console.log("Apple OAuth flow cancelled or failed.");
    }
  };

  return (
    <Pressable onPress={startAppleAuth}>
      <View className="flex-row items-center justify-center px-4 py-3 my-1 bg-black rounded-full">
      <Image
          source={require('../../assets/AppleLogoBlack32px.png')} // Adjust the path to your PNG file
          style={{
            width: 24, // Desired width of the image
            height: 24, // Desired height of the image
          }}
          tintColor={'white'}
          resizeMode='contain' // Ensures the image scales properly without being cropped
        />
        <Text className="ml-4 text-lg font-medium text-white">
          Continue with Apple
        </Text>
      </View>
    </Pressable>
  );
}

export default AppleAuthButton;