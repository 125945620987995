import React, { Component, useState, useEffect, useCallback, useMemo } from "react";
import {
  StyleSheet,
  Text,
  View,
  Platform,
  Dimensions,
  Pressable,
  Image,
} from "react-native";
import {debounce} from 'lodash';

import loadGoogleMapsAPI from "./webMapComponent"; // Import the function

import MapView, {Marker, Polyline} from '@preflower/react-native-web-maps'
import { ChargerAttributes } from "../models/charger-model";
import { useSelector } from "react-redux";
import { RootState } from "../store";
import * as Location from 'expo-location';
import { notificationService } from "../services/notification-service";

// import availableCharger from '../../assets/availableCharger.png';
// import unAvailableCharger from '../../assets/unavailableCharger.png';
// import selectedChargerMap from '../../assets/selectedCharger.png';

import AvailableChargerIcon from '../../assets/ChargerAvailableIcon.png';
import UnAvailableChargerIcon from '../../assets/ChargerUnavailableIcon.png';
import SelectedChargerIcon from '../../assets/ChargerSelectedIcon.png';

import currentLocationIcon from '../../assets/currentLocation.png';
import { PanGestureHandler, State } from "react-native-gesture-handler";

//let MapViewMob: typeof MapView | undefined, MarkerMob: typeof Marker | undefined;

let MapViewMob: any, MarkerMob: any, MapViewDirectionsMob: any;
// if (Platform.OS === "android" || Platform.OS === "ios") {
//   MapViewMob = require("react-native-maps").default;
//   MarkerMob = require("react-native-maps").Marker;
//   MapViewDirectionsMob = require("react-native-maps-directions").default;
// }
const { width, height} = Dimensions.get('window');

//const availableChargerIcon = <Image source={AvailableChargerIcon} style={{ width: 32, height: 32 }} />;

// Define types for the geographic coordinates
interface Coordinates {
  latitude: number;
  longitude: number;
  latitudeDelta?: number;
  longitudeDelta?: number;
  selectCharger: boolean;
}
const initialCoordinates: Coordinates = {
  latitude: 39.80771531693827, 
  latitudeDelta: 0.19248255641330156, 
  longitude: -104.99612951974733, 
  longitudeDelta: 0.08789062499999994,
  selectCharger: false
}

const MapScreen = (props: {
  markerOpenBottomSheet: (charger: ChargerAttributes) => void,
  markerCloseBottomSheet: () => void,
  myInputLocation: Location.LocationObjectCoords | null,
  selectCharger: any,
  onMapInteraction: (interact: boolean) => void,
}) => {
  const [coords, setCoords] = useState<Array<{ latitude: number; longitude: number }>>([]);
  const [googleMapsLoaded, setGoogleMapsLoaded] = useState<boolean>(false);
  const [origin, setOrigin] = useState<Location.LocationObjectCoords | null>({ ...props.myInputLocation, latitude: props.myInputLocation?.latitude, longitude: props.myInputLocation?.longitude });
  const [destination, setDestination] = useState<Location.LocationObjectCoords | null>({ ...props.myInputLocation, latitude: props.myInputLocation?.latitude, longitude: props.myInputLocation?.longitude });
  const [selectedCharger, setSelectedCharger] = useState<any>();
  const [isMapInteraction, setIsMapInteraction] = useState(false);
  // const [region, setRegion] = useState<Coordinates>({
  //   latitude: 33.8252956,
  //   longitude: -117.8307728,
  //   latitudeDelta: 0.05,
  //   longitudeDelta: 0.045,
  // });
  const [myLocation, setMyLocation] = React.useState<Location.LocationObjectCoords | null>(props.myInputLocation);
  //const [waypoint] = useState<Coordinates>({ latitude: 33.8589565, longitude: -117.9589782 });

  const [myCurrentLocation, setMyCurrentLocation] = React.useState<Coordinates>(initialCoordinates);

  const chargerList: ChargerAttributes[] = useSelector(
    (state: RootState) => state.chargers.chargers
  ) as ChargerAttributes[];

  useEffect(() => {
    if (Platform.OS === "web") {
      loadGoogleMapsAPI(() => {
        setGoogleMapsLoaded(true);
      });
    }

  //   const requestLocation = async () => {
  //     try {
  //         const { status } = await Location.requestForegroundPermissionsAsync();

  //         if (status === 'granted') {
  //             const location = await Location.getCurrentPositionAsync({});
  //             setMyLocation({...myLocation, latitude: location.coords.latitude, longitude: location.coords.longitude});
  //             //setCoords(location.coords)
  //           } else {
  //             Platform.OS === 'web' ? '' :
  //             notificationService.sendNotification('error', 'Location permission denied');
  //         }
  //     } catch (error) {
  //         notificationService.sendNotification('error', `Error requesting location permission: ${error}`);
  //     }
  // };
  // requestLocation();
  }, [])

  useEffect(() => {
    setOrigin(myLocation);
    setDestination(myLocation);
  }, [myLocation])


  useEffect(() => {
    const getLocation = () => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const { latitude, longitude } = position.coords;
            
            setMyCurrentLocation({...myCurrentLocation, latitude: latitude, longitude: longitude});
            //setCoords([latitude, longitude])
          },
          (error) => console.error('Error getting location', error),
          { enableHighAccuracy: true, timeout: 20000, maximumAge: 1000 }
        );
      } else {
        console.error('Geolocation is not supported by this browser.');
      }
    };

    getLocation();
  }, []);

  //const validChargers = chargerList?.filter(charger => charger.lat !== null && charger.lng !== null);

  // let filteredChargers = [];
  // if(Array.isArray(validChargers) && validChargers.length > 0){
  //   if(props?.selectCharger === true){ 
  //       filteredChargers = validChargers?.map((selectCharger) => {
  //         if (selectCharger.uuid === selectedCharger?.uuid) {
  //           return { ...selectCharger, isSelected: true };
  //         }
  //         return selectCharger;
  //       });
  //   } else{
  //     filteredChargers = validChargers?.map((selectCharger) => {
  //       if (selectCharger.uuid === selectedCharger?.uuid) {
  //         return { ...selectCharger, isSelected: false };
  //       }
  //       return selectCharger;
  //     });
  //   }
  // }

  const filteredChargers = useMemo(() => {
    const validChargers = chargerList?.filter(
      (charger) => charger.lat !== null && charger.lng !== null
    );
  
    if (Array.isArray(validChargers) && validChargers.length > 0) {
      return validChargers.map((selectCharger) => {
        if (selectCharger.uuid === selectedCharger?.uuid) {
          return { ...selectCharger, isSelected: props?.selectCharger };
        }
        return { ...selectCharger, isSelected: false };
      });
    }
  
    return [];
  }, [chargerList, props?.selectCharger, selectedCharger]);
  
  const currentLocationuuid = '82d87d96-88a2-464e-b7af-1b0850d82754';

  const {latitude, longitude} = myCurrentLocation

  const currentLocation = {
    uuid: currentLocationuuid,
    name:'Current Location',
    isCurrentLocation: true,
    lat: latitude, 
    lng: longitude
  }
  //filteredChargers.push(currentLocation)

  const handleMarkerPress = useCallback(
    (charger: ChargerAttributes) => {
      // Check if the marker is not the user's location
      if (
        !(
          charger.lat === props.myInputLocation?.latitude &&
          charger.lng === props.myInputLocation?.longitude
        )
      ) {
        props?.markerOpenBottomSheet(charger);
      }
      setSelectedCharger(charger);
    },
    [props.myInputLocation, props.markerOpenBottomSheet] // Add dependencies here
  );
  
  //build marker list for map
  const markerList: JSX.Element[] = filteredChargers.map((charger) => (
    <Marker
      key={charger.uuid}
      coordinate={{ latitude: charger.lat!, longitude: charger.lng! }}
      title={charger.name}
      // Pass the image source directly to the icon prop
      icon={
        charger?.isOccupied !== 1 &&
        //(charger.status === 'complete' || charger.status === null) &&
        (charger?.isSelected === undefined || charger?.isSelected === false)
          ? AvailableChargerIcon
          : charger?.isSelected === true
          ? SelectedChargerIcon
          : UnAvailableChargerIcon
      }
      onPress={() => handleMarkerPress(charger)}
      zIndex={2}
    />
  ));
  
  //  useEffect(() => {
  //   //console.log("location is", myLocation);  
  //  }, [myLocation]);

  //  useEffect(() => {
  //   console.log("charger list is: ", chargerList);
  //  }, [chargerList]);

  // Create a debounced version of onRegionChange using useCallback
  const debouncedOnRegionChange = useCallback(debounce((newRegion: any) => {
    // Validate the latitude and longitude
    //console.log("newRegion is", newRegion);
    if (!isNaN(newRegion.latitude) && 
        !isNaN(newRegion.longitude) &&
        isFinite(newRegion.latitude) && 
        isFinite(newRegion.longitude)) {
      //setRegion(newRegion);
      setMyLocation(newRegion);
    }
  }, 10), []);

  const onRegionChangeComplete = (region) => {
    //console.log("Region changed:", region);
  };

  const onPress = (event) => {
    //console.log("Map pressed:", event.nativeEvent.coordinate);
  };

  const onDoublePress = (event) => {
    //console.log("Map double pressed:", event.nativeEvent.coordinate);
  };

  const onPanDrag = () => {
    //console.log("Map panned or dragged");
  };
  //const { origin, destination, googleMapsLoaded, coords } = this.state;

  const onGestureEvent = (event: any) => {
    if (event.nativeEvent.state === State.BEGAN) {
      // Gesture has started, meaning user is interacting with the map
      console.log("beginning swipe");
      setIsMapInteraction(true);
    } else if (event.nativeEvent.state === State.END || event.nativeEvent.state === State.CANCELLED) {
      // Gesture has ended or been cancelled, allow TabView to handle gestures again
      setIsMapInteraction(false);
    }
  };

  if(latitude !== initialCoordinates?.latitude && longitude !== initialCoordinates?.longitude
  ){
    return(
        <View style={[styles.container, {height: height}]}>
          {googleMapsLoaded && Platform.OS === "web" ? (
            <View style={styles.container}>
              <PanGestureHandler 
              onGestureEvent={onGestureEvent}
              onHandlerStateChange={onGestureEvent}
              minDist={10} // This can be adjusted based on sensitivity needed
            >
              <MapView
                style={styles.map}
                initialRegion={{
                  latitude:  latitude,
                  longitude: longitude
                }}
                onRegionChange={(new_region) => {
                  props.onMapInteraction(true);
                  debouncedOnRegionChange(new_region);
                }}
                onRegionChangeComplete={onRegionChangeComplete}
                onPress={onPress}
                onDoublePress={onDoublePress}
                onPanDrag={onPanDrag}
                zoomEnabled={true}
                zoomControlEnabled={true}
                mapType="terrain"
                showsPointsOfInterest={false}
                showsUserLocation={false}
                showsMyLocationButton={true}
                followUserLocation
              >
                {/* <Marker coordinate={myLocation} pinColor="blue" /> */}
              {markerList}
              <Marker 
                key={`myLocationMarker`}
                coordinate={{
                  latitude: props.myInputLocation?.latitude ?? 0, 
                  longitude: props.myInputLocation?.longitude ?? 0
                }} //typescript not null assertion !
                title={'Current Location'} 
                icon={currentLocationIcon}
                zIndex={1}
              />
  
                {/* {coords && (
                  <MapView.Polyline
                    // coordinates={coords.map((coord) => ({
                    //   latitude: coord[0],
                    //   longitude: coord[1],
                    // }))}
                    coordinates={coords}
                    strokeWidth={8}
                    strokeColor="royalblue"
                    tappable={true}
                    onClick={() => {
                      onPolylineClicked();
                    }}
                  />
                )} */}
              </MapView>
              </PanGestureHandler>
            </View>
          ) : Platform.OS === "android" || Platform.OS === "ios" ? (
            <View style={styles.container}>
              <MapViewMob
                style={styles.map}
                onRegionChange={(new_region) => {
                  debouncedOnRegionChange(new_region);
                }}
              >
                <MarkerMob coordinate={origin} title="Origin">
                  <View style={styles.markerContainer}></View>
                </MarkerMob>
  
                <MarkerMob coordinate={destination} title="Destination">
                  <View style={styles.markerContainer}></View>
                </MarkerMob>
  
                {markers &&
                  markers.map((marker, index) => (
                    <MarkerMob
                      key={index}
                      coordinate={marker.latlng}
                      title={marker.title}
                      description={marker.description}
                    />
                  ))}
  
                {plot.draw && (
                  <MapViewDirectionsMob
                    origin={origin}
                    destination={destination}
                    waypoint={plot.waypoint}
                    strokeColor={showIcon ? "red" : "royalblue"}
                    tappable={true}
                    onPress={() => {
                      onPolylineClicked();
                    }}
                    apikey={apiKey}
                    strokeWidth={14}
                  />
                )}
              </MapViewMob>
            </View>
          ) : (
            <Text>LOADING....</Text>
          )}
        </View>
      );
  }
  }

const styles = StyleSheet.create({
  container: {
    ...StyleSheet.absoluteFillObject,
  },
  map: {
    ...StyleSheet.absoluteFillObject,
    borderColor: "white",
    // borderWidth: 8,
    // borderTopWidth: 4,
    // borderBottomWidth: 4,
  },
  markerContainer: {
    width: 40,
    height: 40,
  },
  markerImage: {
    flex: 1,
    width: undefined,
    height: undefined,
  },

  rgnText: {
    fontSize: 12,
    color: "#666666",
  },
  rgnView: { flexDirection: "row", alignItems: "flex-end" },
  button: {
    backgroundColor: "orange",
    padding: 8,
    margin: 5,
    borderRadius: 5,
    alignItems: "center",
    height: 35,
  },
  buttonText: {
    color: "white",
    textAlign: "center",
    fontWeight: "bold",
  },
});

export default MapScreen;