import React, {useEffect, useState} from "react";
import {
    SafeAreaView,
    View,
    Text,
    Image,
    Dimensions,
    StyleSheet,
    TextInput,
    ScrollView,
    Pressable
  } from "react-native";
import { FontAwesome } from '@expo/vector-icons';
import { FontAwesome6 } from '@expo/vector-icons';
import { WeekConfiguration, createEmptyWeek, dayNames, generatePeakObj, setPeak } from "../components/common/utils/peak-off-peak-field";
import DropdownComponentEnd from "../components/DropdownComponentEnd";
import GooglePlacesInput from "../components/GooglePlacesInput";
import { 
    getCoordinatesFromGeodata, 
    GetGoogleGeocode as GetGoogleGeodata, 
    getAddressComponentsFromGeodata 
} from '../components/common/utils/google-api-utils';
import { Place } from "react-native-google-places-autocomplete";
import { 
    disableCharger, 
    enableCharger, 
    getAllChargers, 
    getMyChargers, 
    getResidentials, 
    updateCharger 
} from "../store/actions/charging-actions";
import { PrepareApiProcedure } from "../components/common/utils/prepare-api-procedure";
import { fetchDataFromAPI } from "../services/api-requests";
import {useDispatch, useSelector} from 'react-redux';
import { notificationService } from "../services/notification-service";
import { ChargerAttributes, createEmptyCharger } from "../models/charger-model";
import { RootState } from "../store";
import RegisterChargerCheckbox from "./RegisterChargerCheckbox";
import { AppDispatch } from "../store";

const { width, height } = Dimensions.get("window");

interface AddModifyChargerProps {
    navigation: any;
    route: any;
  }
  
  interface ErrorTextProps {
    message: string;
  }

  // Reusable Error Text Component
const ErrorText: React.FC<ErrorTextProps> = ({ message }) => (
    message ? <Text style={{ color: 'red', marginVertical: 5 }}>{message}</Text> : null
);

const AddModifyCharger: React.FC<AddModifyChargerProps> = ({ navigation, route }) => {

    const [isUpdating, setIsUpdating] = useState(false);
    const [geodata, setGeodata] = React.useState(null);

    const [peakSelection, setPeakSelection] = React.useState(generatePeakObj('000000000000000000000000000000000000000000'));
    const [uuid, setUuid] = React.useState();
    const [editChargerStatus, setEditChargerStatus] = useState(false);
    const [checkboxSelected, setCheckboxSelected] = useState({
        updatedDay: [''],
        startEndTime: [{}]
    });
    const[generatePeakObjCheckbox, setGeneratePeakObjCheckbox] = useState({});
    const [residentialRatePeak, setResidentialRatePeak] = useState<number | null>(null);
    const [residentialRateOffPeak, setResidentialRateOffPeak] = useState<number | null>(null);
    const [chargingCapacity, setChargingCapacity] = useState<number>(route.params.capacity ?? 40); //default 40A
    const [addressErrorMessage, setAddressErrorMessage] = useState<string>('');
    const [nameErrorMessage, setNameErrorMessage] = useState<string>('');
    //const [chargerDetails, setChargerDetails] = useState(null);
    const installedVAC = 220; //220v installation

    const selectedCharger: ChargerAttributes = useSelector((state: RootState) => state.chargers.selectedCharger)

    // const selectedChargerResp = selectedCharger?.filter(charger=>{
    //         if(charger.uuid === chargerDetails.uuid){
    //             return charger;
    //         }
    // })
    
    const [form, setForm] = React.useState<ChargerAttributes>(
        createEmptyCharger()
    );
    const[enableDisCharger, setEnableDisCharger] = React.useState<boolean>(false);

    const dispatch: AppDispatch = useDispatch();

    const setSelectedDays = (objCheckbox: any) => {
        setGeneratePeakObjCheckbox(objCheckbox);
    }

    useEffect( () => {

        const getChargingDetails = async() => {
            //check if we have been passed in a route
            if(route.params){
                if(!route.params.newCharger){
                //const editChargerStatus = route.params?.editCharger;
                setEditChargerStatus(true);
                const chargerDetails = route.params?.chargerDetails;

                const{name, unit_serial, full_address, description, price_hourly_peak, price_hourly_off_peak, peak_selection, uuid} = chargerDetails;
        
                setForm({
                    ...form,
                    name: name,
                    unit_serial: unit_serial,
                    full_address: full_address,
                    description: description,
                    price_hourly_peak: price_hourly_peak,
                    price_hourly_off_peak: price_hourly_off_peak,
                    uuid: uuid
                })

                setPeakSelection(peak_selection);

                const getGeneratedPeakObj = generatePeakObj(peak_selection)
                
                let dateUpdated = [];
                let updatedDateTime = [];

                for (const key in getGeneratedPeakObj) {
                    if (getGeneratedPeakObj?.hasOwnProperty(key)) {
                    if(getGeneratedPeakObj[key].start !== 0 && getGeneratedPeakObj[key].end !== 0){
                        dateUpdated.push(key);
                        updatedDateTime.push(getGeneratedPeakObj[key])
                    }
                    }
                }

                setCheckboxSelected({
                    ...checkboxSelected,
                    updatedDay: dateUpdated,
                    startEndTime: updatedDateTime
                })
                setGeneratePeakObjCheckbox(getGeneratedPeakObj)
                }
            else{
                    setForm({
                        ...form,
                        unit_serial: route.params.unit_serial,
                    })
                }
            }
        }

        getChargingDetails();
    }, [])

    useEffect(()=> {
        const fullAddress = form?.full_address;
        if(fullAddress){
            const googleAddress = {
                description : fullAddress
            }
            handleAddressSelect(googleAddress, '')
        }
        
    },[form?.full_address])

    const handleUpdateCharger = async () => {
        setIsUpdating(true)
        const { description, lat, lng, uuid, name, unit_serial, full_address, street_number, street_name, zip_code, city, state, country, 
            price_hourly_peak, price_hourly_off_peak
        } = form;

        const respPeakSelection = setPeak(peakSelection)

        let cost_est_kwh_peak_update;
        let cost_est_kwh_off_peak_update;

        if( residentialRatePeak !== null && residentialRateOffPeak !== null){
            cost_est_kwh_peak_update  = residentialRatePeak  * chargingCapacity * 2;
            cost_est_kwh_off_peak_update = residentialRateOffPeak * chargingCapacity;
        } else {
            cost_est_kwh_peak_update  = 3 * chargingCapacity;
            cost_est_kwh_off_peak_update = 3 * chargingCapacity;
        }

        const payload = {
            charger_model: "Emporia X1",
            charger_type: "Level 2",
            charging_capacity: 40,
            cost_est_kwh: 2.88,
            description: description,
            manufacturer_name: "Emporia",
            name,
            unit_serial: unit_serial,
            lat,
            lng,
            full_address,
            street_number,
            street_name,
            zip_code,
            city,
            state,
            country,
            price_hourly_peak,
            price_hourly_off_peak,
            cost_est_kwh_peak: cost_est_kwh_peak_update,
            cost_est_kwh_off_peak: cost_est_kwh_off_peak_update,
            peak_selection: respPeakSelection
        }
        
        setTimeout(()=> {
            navigation.navigate('MyChargers',{
                state: route?.params?.state === false ? true : false
            });
        },2000)

        dispatch(updateCharger(payload, uuid))
    }

    const onEnableDisableCharger = async () => {
        setEnableDisCharger(!enableDisCharger);
        const enableDisableuuid = route.params?.chargerDetails?.uuid
        if(enableDisCharger === true){
            //dispatch(disableCharger(enableDisableuuid))
            await disableCharger(enableDisableuuid);
        }
        else{
            //dispatch(enableCharger(enableDisableuuid))
            await enableCharger(enableDisableuuid);
        }

    }

    const handleSubmit = async (searchString: any) => {
        // Validate required fields
        if (!form.full_address) {
          setAddressErrorMessage('Please select a valid address');
          return;
        }
        if (!form.name) {
          setNameErrorMessage('Please enter a charger name');
          return;
        }
      
        // Async call to set peak selection
        const respPeakSelection = await setPeak(peakSelection);
      
        // Helper function for cost calculation with error handling
        const calculateCost = (rate: number | null, multiplier: number) => {
          if (rate === null || rate < 0 || isNaN(rate)) {
            console.error(`Invalid rate provided: ${rate}`);
            return 3 * multiplier; // Default value in case of invalid input
          }
          return rate * multiplier;
        };
      
        // Perform cost calculations
        const cost_est_kwh_peak_update = calculateCost(residentialRatePeak, chargingCapacity * 2);
        const cost_est_kwh_off_peak_update = calculateCost(residentialRateOffPeak, chargingCapacity);
      
        // Clone form and remove the uuid property
        const { uuid, ...formWithoutUuid } = form;

        // Prepare payload by spreading form directly and adding additional fields
        const payload = {
          ...formWithoutUuid,
          charger_model: "Emporia X1",
          charger_type: "Level 2",
          charging_capacity: 40,
          cost_est_kwh_peak: cost_est_kwh_peak_update,
          cost_est_kwh_off_peak: cost_est_kwh_off_peak_update,
          peak_selection: respPeakSelection,
          manufacturer_name: "Emporia", // Additional field
        };
      
        try {
          // Prepare API query and send request
          const apiQuery = PrepareApiProcedure('charger/registerCharger', 'POST', 'charger', payload);
          const registerResp = await fetchDataFromAPI(apiQuery);
      
          // Handle registration response
          if (!registerResp) {
            notificationService.sendNotification("error", "Could not register this charger");
          } else {
            notificationService.sendNotification("success", "Successfully registered your charger!");
            setUuid(registerResp?.charger?.uuid);
            dispatch(getMyChargers());
            navigation.navigate('MyChargers');
          }
        } catch (error) {
          console.error("Error while registering charger", error);
        }
    };

    const handleAddressSelect = async (data: Place, details: any) => {
        const newGeodata = await GetGoogleGeodata(data.description);
        const { latitude, longitude } = getCoordinatesFromGeodata(newGeodata);
        const addressComponents = getAddressComponentsFromGeodata(newGeodata);
    
        if (!latitude || !longitude) {
          setAddressErrorMessage('Please select a valid address');
        } else {
          setAddressErrorMessage('');
          setForm({
            ...form,
            full_address: data.description,
            lat: latitude,
            lng: longitude,
            ...addressComponents,
          });
          setGeodata(newGeodata);
        }
      };

    React.useEffect(() => {
        const getResidentialRates = async () => {
            const residentials = await getResidentials(form.lat.toString(), form.lng.toString());
            setResidentialRateOffPeak(residentials?.outputs.residential)
            setResidentialRatePeak(residentials?.outputs.residential * 2)
        }
        if(form.lat && form.lng){
            getResidentialRates();
        }
    }, [form])

    React.useEffect(() => {
        setTimeout(()=>{
            dispatch(getAllChargers());
        },2000)
    }, [enableDisCharger]);
    
    return (
        <SafeAreaView style={styles.safeArea}>
            <View style={[styles.containerBgColor, {height: height - 65, paddingBottom: 10, flex: 1}]}>
                <ScrollView>
                    <View className="relative">
                        <Image
                        source={require("../../assets/RegisterBgImage.png")}
                        style={{ aspectRatio: 1}}
                        alt="Background Image"
                        />
                        <Pressable className="absolute bottom-0 right-0 p-2 mb-2 mr-2 rounded-lg" style={{backgroundColor: '#0B1E1D'}}>
                            <Text className="text-sm font-semibold text-white">Change Photo</Text>
                        </Pressable>
                    </View>
                    
                    <View className="p-6">
                        {
                            editChargerStatus && (
                                <View>
                                    {selectedCharger.isOccupied === 1 || enableDisCharger === true? (
                                        <Pressable style={{backgroundColor: '#FBD644'}} className="p-3 mt-4 rounded-full" onPress={onEnableDisableCharger}>
                                            <Text className="text-lg font-bold text-center text-black text-white">Disable Charger</Text>
                                        </Pressable>
                                    ):(
                                        <Pressable style={{backgroundColor: '#008000bf'}} className="p-3 mt-4 rounded-full" onPress={onEnableDisableCharger}>
                                            <Text className="text-lg font-bold text-center text-black text-white">Enable Charger</Text>
                                        </Pressable>
                                    )}

                                    <Text className="mt-2 text-xs font-medium text-white">Temporarily disable or enable your charger -  Guests will not be able to find it or charge with it through the app. This is useful if you want to manually use the charger yourself without the app.</Text>
                                    <View style={styles.line} />
                                </View>
                            )
                        }
                        <Text className="pt-3 pb-2 text-base font-semibold text-white">Charger Name</Text>
                        <TextInput 
                            placeholder="Enter charger name"
                            placeholderTextColor={'#8D8E90'}
                            style={styles.BgColor1}
                            className="w-full p-3 text-white rounded-full"
                            value={form?.name}
                            autoComplete="off"
                            autoCorrect={false}
                            onChange={(e) => {
                                setForm({
                                    ...form,
                                    name: e.nativeEvent.text
                                })
                              }}
                        />
                        <ErrorText message={nameErrorMessage} />
                        <Text className="pt-3 pb-2 text-base font-semibold text-white">Serial Number</Text>
                        <TextInput 
                            placeholder="Enter serial number"
                            placeholderTextColor={'#8D8E90'}
                            style={styles.BgColor1}
                            className="w-full p-3 text-white rounded-full"
                            value={form?.unit_serial}
                            autoComplete="off"
                            autoCorrect={false}
                            // onChange={(e) => {
                            //     setForm({
                            //         ...form,
                            //         unit_serial: e.nativeEvent.text
                            //     })
                            //   }}
                        />
                        <Text className="pt-3 pb-2 text-base font-semibold text-white">Street Address</Text>
                        <GooglePlacesInput onPlaceSelect={handleAddressSelect} value={form?.full_address}/>
                        <ErrorText message={addressErrorMessage} />

                        <Text className="pt-3 pb-2 text-base font-semibold text-white">Charger Description</Text>
                        <TextInput 
                            placeholder="Enter charger description"
                            placeholderTextColor={'#8D8E90'}
                            multiline={true}
                            numberOfLines={5}
                            style={[styles.BgColor1, { borderRadius: 16 }]}
                            className="w-full p-3 text-white"
                            value={form?.description}
                            onChange={(e) => {
                                setForm({
                                    ...form,
                                    description: e.nativeEvent.text
                                })
                              }}
                        />

                        <View style={styles.line} />

                        <View>
                            <Text className="text-base font-semibold text-white">Hourly Rate</Text>
                            <View className="flex flex-row items-center gap-4">
                                <Text className="w-24 text-base font-semibold text-white">Peak:</Text>
                                <View className="flex flex-row items-center gap-1">
                                    <FontAwesome name="dollar" size={18} color="white" />
                                    <TextInput 
                                        placeholder="25.00"
                                        placeholderTextColor={'#8D8E90'}
                                        style={[styles.BgColor2, { borderRadius: 30 }]}
                                        className="w-24 p-3 text-white"
                                        value={form?.price_hourly_peak}
                                        autoComplete="off"
                                        autoCorrect={false}
                                        onChange={(e) => {
                                            setForm({
                                                ...form,
                                                price_hourly_peak: Number(e.nativeEvent.text)
                                            })
                                          }}
                                    />
                                </View>
                                <Text className="text-xs font-medium text-white">per hour before fees</Text>
                                { residentialRatePeak &&
                                    <Text style={{ color: 'red' }} className="text-xs">
                                        Est: ${(residentialRatePeak * (chargingCapacity * installedVAC)/1000).toFixed(2)}/hr
                                    </Text>
                                }
                            </View>
                            <View className="flex flex-row items-center gap-4">
                                <Text className="w-24 text-base font-semibold text-white">Off-Peak:</Text>
                                <View className="flex flex-row items-center gap-1">
                                    <FontAwesome name="dollar" size={18} color="white" />
                                    <TextInput 
                                        placeholder="35.00"
                                        placeholderTextColor={'#8D8E90'}
                                        style={[styles.BgColor2, { borderRadius: 30 }]}
                                        className="w-24 p-3 text-white"
                                        value={form?.price_hourly_off_peak}
                                        autoComplete="off"
                                        autoCorrect={false}
                                        onChange={(e) => {
                                            setForm({
                                                ...form,
                                                price_hourly_off_peak: Number(e.nativeEvent.text)
                                            })
                                          }}
                                    />
                                </View>
                                <Text className="text-xs font-medium text-white">per hour before fees</Text>
                                { residentialRateOffPeak &&
                                    <Text style={{ color: 'red' }} className="text-xs">
                                        Est: ${(residentialRateOffPeak * (chargingCapacity * installedVAC)/1000).toFixed(2)}/hr
                                    </Text>
                                }
                            </View>
                        </View>

                        <View style={styles.line} />

                        <View>
                            <Text className="mb-4 text-base font-semibold text-white">Peak Hours</Text>
                            <RegisterChargerCheckbox 
                                setPeakSelection={setPeakSelection} 
                                checkboxSelected={checkboxSelected}
                                generatePeakObjCheckbox={generatePeakObjCheckbox} 
                                setSelectedDays={setSelectedDays}
                                editChargerStatus={editChargerStatus}
                            />
                        </View>
                        {editChargerStatus ===  true ? (
                            <Pressable onPress={handleUpdateCharger} style={{backgroundColor: '#39BEB7'}} className="p-3 mt-4 rounded-full">
                            <Text className="text-lg font-medium text-center text-white">Update Charger</Text>
                            </Pressable>
                        ) : (
                            <Pressable onPress={handleSubmit} style={{backgroundColor: '#39BEB7'}} className="p-3 mt-4 rounded-full">
                            <Text className="text-lg font-medium text-center text-white">Create Charger</Text>
                            </Pressable>
                        )}
                    </View>
                </ScrollView>
            </View>
        </SafeAreaView>
    )
}

export default AddModifyCharger;

const styles = StyleSheet.create({
    safeArea: {
        flex: 1,
        backgroundColor: '#0B1E1D',
    },
    containerBgColor: {
        backgroundColor: '#0B1E1D',
    },
    BgColor1: {
        backgroundColor: '#292D33'
    },
    BgColor2: {
        backgroundColor: '#234D4E'
    },
    line: {
        borderBottomColor: "#636363",
        borderBottomWidth: 1,
        borderStyle: 'dashed',
        marginVertical: 20,
    },
    row: {
        flexDirection: 'row',
        alignItems: 'center',
        gap: 2
    },
    checkbox: {
        width: 20,
        height: 20,
        borderWidth: 1,
        borderColor: 'white',
        justifyContent: 'center',
        alignItems: 'center',
        marginRight: 10,
        borderRadius: 5,
    },
})