import React, { useRef, useState, useEffect, useCallback } from 'react';
import { 
    View, 
    StyleSheet, 
    SafeAreaView, 
    Dimensions, 
    ScrollView, 
    Text, 
    Image, 
    Pressable, 
    Platform, 
    Linking 
} from 'react-native';
import { Video, ResizeMode } from 'expo-av';
import { 
    getActiveCharges, 
    loginUser, 
    oauthLogin, 
    registerUser, 
    updateUser, 
    validateUserToken 
} from '../store/actions/user-actions';
import { notificationService } from '../services/notification-service';
import { createUserSession, UserSessionAttributes } from '../models/user-session-model';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../store';
import SignInOptions from './SignInOptions';
import { getIpAddressFunc } from '../components/common/utils/ip-address';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { UserInfo } from '../models/user-info';
import { createUser } from '../models/login-register-model';
import { setSession } from '../store/actions/user-session';
import { getAllChargers } from '../store/actions/charging-actions';
import { storeUserSession, getUserSession, getUserSessionObj } from '../services/user-session-service';
import { AppDispatch } from '../store';
import ActiveChargeCard from './ActiveChargeCard';
import { useFocusEffect } from '@react-navigation/native';

const { width, height } = Dimensions.get('window');

const WelcomeScreen = ({navigation, route}:any) => {

    const [ipAddress, setIpAddress] = useState<string | null>("");
    const [userToken, setUserToken] = useState<string>("");
    const [userSession, setUserSession] = useState<UserSessionAttributes | undefined>(undefined)
    let navDestination = 'MyChargers'
    const session: UserSessionAttributes | undefined = useSelector((state: RootState) => state.user.session);
    const dispatch: AppDispatch = useDispatch();

    const refRBSheet = useRef();
    const OpenBottomSheet = () => {
        if(refRBSheet && refRBSheet.current){
            refRBSheet.current.open();
        }
    }

    const logout = route?.params?.token;

    useEffect(() => {
        const verifySession = async () => {
            const sessionVerify: UserSessionAttributes = await validateUserToken();
            if (sessionVerify.msg.toLowerCase() != 'ok') {
                AsyncStorage.clear()
            } else {
                await AsyncStorage.setItem("token", sessionVerify.access_token);
                await AsyncStorage.setItem("IpAddress", sessionVerify.IpAddress);
                await AsyncStorage.setItem("email", sessionVerify.email);
                await AsyncStorage.setItem("role", sessionVerify.role);
                await AsyncStorage.setItem("userId", sessionVerify.id);
                await storeUserSession(sessionVerify);
            }
        }
        setUserSession(session);
        
        if(session){
            verifySession();
        }
    }, [session])

    // OAuth Return Message Listener in a separate useEffect
  useEffect(() => {
    const oauthReturnMessage = async (event: MessageEvent) => {
      if (event.origin !== "https://app.meanderev.co:8803") return;

      const profile: UserInfo = event.data as UserInfo;
      const ipAddress = await getIpAddressFunc();
      const { sub, email, firstname, lastname } = profile;
      const authType = event.data.authType;

      const loginObj = createUserSession({
        email,
        password: sub.toString(),
        IpAddress: ipAddress,
        loginType: authType === 'google',
      });

      const loginResp = await loginUser(loginObj);

      if (loginResp === null && userSession === undefined) {
        const registerObj = createUser({
          email,
          password: sub.toString(),
          username: email,
          first_name: firstname,
          last_name: lastname,
          role: 'user',
        });

        const registerResp = await registerUser(registerObj);

        if (registerResp?.length > 0) {
          const loginObjAfterRegister = createUserSession({
            email,
            password: sub.toString(),
            IpAddress: ipAddress,
          });

          const loginRespAfterRegister = await loginUser(loginObjAfterRegister);
          if (loginRespAfterRegister?.length > 0) {
            await AsyncStorage.setItem("userSession", JSON.stringify(loginRespAfterRegister));
            dispatch(setSession(loginRespAfterRegister));
            navigation.navigate("ChargerSelection", {
              userInfo: loginObj,
              auth: authType === 'google' ? "googleOAuth" : "appleOAuth",
            });
            notificationService.sendNotification("success", "Login Successful!");
          } else {
            console.log("Error retrieving the login token");
          }
        } else {
          notificationService.sendNotification("error", "Registration issue, please try again");
        }
      } else if (loginResp === null && userSession !== undefined) {
        dispatch(updateUser(userSession, firstname, lastname, email, sub.toString()));

      } else {
        await AsyncStorage.setItem("userSession", JSON.stringify(loginResp));
        dispatch(setSession(loginResp));
        navigation.navigate("MyChargers", {
          userInfo: loginObj,
          auth: authType === 'google' ? "googleOAuth" : "appleOAuth",
        });
      }

      dispatch(oauthLogin(profile));

      if (event.data.closeWindow) {
        window.close();
      }
    };

    window.addEventListener('message', oauthReturnMessage);

    return () => {
      window.removeEventListener('message', oauthReturnMessage);
    };
  }, [dispatch, navigation, userSession]);

  // Run these functions only once on component mount
  useEffect(() => {
    const initializeApp = async () => {
      const getUserIp = async () => {
        const ip = await getIpAddressFunc();
        setIpAddress(ip);
      };

      const isUserSignedIn = async () => {
        const token = await AsyncStorage.getItem("token");
        if (token) {
          setUserToken(token);
          const existingSession = await getUserSession();
          if (existingSession) {
            setUserSession(existingSession);
            dispatch(setSession(existingSession));
          } else {
            const validatedSession = await validateUserToken();

            if (validatedSession.errStatus === 401) {
              await AsyncStorage.clear();
              notificationService.sendNotification("error", validatedSession.msg);
            } else {
              await AsyncStorage.setItem("userSession", JSON.stringify(validatedSession));
              dispatch(setSession(validatedSession));
            }
          }
        } else {
          await AsyncStorage.clear();
        }
      };

      await getUserIp();
      await isUserSignedIn();
      dispatch(getActiveCharges());
    };

    initializeApp();
  }, [dispatch]);

  // Validate token and fetch chargers when IP address and token are set
  useEffect(() => {
    const validateAndFetchChargers = async () => {
      const validationResp = await validateUserToken();
      if (validationResp.msg.toLowerCase() !== "ok") {
        await AsyncStorage.clear();
      } else {
        dispatch(getAllChargers());
      }
    };

    if (ipAddress && userToken) {
      validateAndFetchChargers();
    }
  }, [ipAddress, userToken, dispatch]);

    const handleManagePress = async () => {
        console.log("manage EV pressed")
        //check if user already logged in
        //if user is not logged in, bring to sign in/sign up page
        // if they are logged in, bring to "my chargers" page
        navDestination = "MyChargers";
        if(userToken && userSession?.role !== 'guest'){
            const resp = await validateUserToken();
            await storeUserSession(resp);
            //now the session object should be in resp
            if (!resp || resp.msg.toLowerCase() !== "ok"){
                notificationService.sendNotification('error', 'You are not logged in!');
                // clear the cache
                const keys = await AsyncStorage.getAllKeys();
                await AsyncStorage.multiRemove(keys);
                OpenBottomSheet();
            } else {
                navigation.navigate(navDestination);
            }
        } else {
            notificationService.sendNotification('error', 'You are not logged in!');
            OpenBottomSheet();
        }
    }

    const handleNavPress = async (tabSelect: string) => {
        const createSession = await getUserSession();
        if(createSession && createSession.access_token !== ''){
            setUserSession(createSession);
            dispatch(setSession(createSession));
            navigation.navigate("ChargerSelection", {tabSelect: tabSelect});
        } else {
            notificationService.sendNotification("error", "Error Creating Session");
        }
    }

    const { width: windowWidth, height: windowHeight } = Dimensions.get('window');

    // Max size for the video to prevent it from being too large
    const videoWidth = 1440;
    const videoHeight = 1440;
    const aspectRatio = videoWidth / videoHeight;
    const maxVideoWidth = windowWidth <= 1440 ? windowWidth : 1440;
    const maxVideoHeight = maxVideoWidth / aspectRatio;
    const verticalOffset = -height * 0.25;
    // console.log ("width:", maxVideoWidth);
    // console.log ("height", maxVideoHeight);
    return (
        <SafeAreaView style={styles.container}>
            <View style={[styles.containerBgColor, { height: height, width: width <= 430 ? width : 430 }]}>
                <ScrollView contentContainerStyle={styles.scrollContainer}>
                    <View style={styles.videoContainer}>
                        <Video
                            //source={require('../../assets/splashVideo.mp4')} //video URL
                            //source={require('../../assets/splashScreenHD.mov')}
                            source={require('../../assets/meanderstars1440.mp4')}
                            style={[styles.video, { width: 1440, height: 1440, transform: [{scale: .65}, { translateY: verticalOffset}] }]}
                            //resizeMode={ResizeMode.COVER}
                            resizeMode={ResizeMode.CONTAIN}
                            rate={1.0}
                            shouldPlay
                            isLooping
                            isMuted
                            useNativeControls={false}
                        />
                    </View>
                    <View style={styles.overlay}>
                            
                        <View style={styles.textBgColor}>
                            <Image 
                                source={require('../../assets/mountainSplash.png')} 
                                style={styles.mountainSize}
                                resizeMode='contain'
                            />
                            <Image source={require('../../assets/chargingStation.png')} style={styles.chargingStation}/>
                            <View className='p-3'>
                                <Text className='text-2xl font-bold text-center text-white'>Start charging immediately or easily find EV charging stations around you</Text>
                                <Text className='pt-1 text-sm font-normal text-center text-white'>Scan a nearby charger’s QR code to begin charging immediately or find a charger near you.</Text>
                                <View className='flex flex-row justify-between pt-4'>
                                    <Pressable
                                        style={styles.chargerStationBtn}
                                        onPress={() => {
                                            handleNavPress('ScanQr');
                                        }}
                                    >
                                        <Text style={styles.textStyle}>
                                            Scan QR code
                                        </Text>
                                    </Pressable>
                                    <Pressable
                                        style={styles.chargerStationBtn1}
                                        onPress={() => {
                                            handleNavPress('Map');
                                        }}
                                    >
                                        <Text style={styles.textStyle1}>
                                            Find a charger
                                        </Text>
                                    </Pressable>
                                </View>
                                <Pressable 
                                onPress={handleManagePress}>
                                    <Text className='py-3 text-sm font-normal text-center text-white' style={styles.textStyle2}>
                                        Manage a charger
                                    </Text>
                                </Pressable>
                            </View>
                        </View>
                    </View>
                </ScrollView>
                <SignInOptions
                    navigation={navigation}
                    refRBSheet={refRBSheet}
                    destination={'MyChargers'}
                />
            </View>
        </SafeAreaView>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
    },
    containerBgColor: {
        backgroundColor: '#1A1D21',
        flex: 1,
    },
    scrollContainer: {
        flexGrow: 1,
    },
    videoContainer: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
    },
    video: {
        position: 'absolute',
        // top: 0,
        // left: 0,
        // bottom: 0,
        // right: 0,
        //width: '100%',
        //height: '100%',
        //aspectRatio: 1080 / 1920,
        //aspectRatio: 1400 / 1400,
        alignSelf: 'center',
        //resizeMode: 'contain',
        transform: [
            { scale: 1 },
            { translateY: 0},
            { translateX: 0 },
            { rotate: '0deg'},
        ],
    },
    overlay: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        justifyContent: 'center',
        alignItems: 'center',
    },
    text: {
        color: '#FFFFFF',
        fontSize: 24,
        fontWeight: 'bold',
        position: 'absolute'
    },
    mountainSize: {
        width: '100%',
        height: '100%',
        position: 'absolute',
        bottom: 210
    },
    chargingStation: {
        top: 0,
        left: '50%',
        width: 40,
        height: 90,
    },
    textBgColor: {
        backgroundColor: '#020706',
        position: 'absolute',
        bottom: 0,
    },
    textStyle: {
        color: "#000000",
        fontWeight: '500',
        textAlign: "center",
        fontSize: 16,
      },
      chargerStationBtn:{
        borderRadius: 45,
        paddingHorizontal: 30,
        paddingVertical: 14,
        backgroundColor: "#10F4E9",
        fontSize: 12
      },
      chargerStationBtn1: {
        borderColor: '#ffff',
        borderWidth: 2,
        borderRadius: 45,
        paddingHorizontal: 30,
        paddingVertical: 14,
        fontSize: 12
      },
      textStyle1: {
        color: "#ffffff",
        fontWeight: '500',
        textAlign: "center",
        fontSize: 16,
      },
      textStyle2: {
        color: "#8D8E90",
      }
});

export default WelcomeScreen;