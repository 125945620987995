import React, { useEffect, useState } from 'react';

import { Modal, SafeAreaView, View,Text, ScrollView, StyleSheet, Dimensions, TextInput, Image, Pressable } from 'react-native';
import { MaterialIcons } from '@expo/vector-icons';
import { useDispatch, useSelector } from 'react-redux';
import { ChargerAttributes } from '../models/charger-model';
import { notificationService } from "../services/notification-service";
import { ChargerHistoryAttributes } from '../models/charger-history-model';
import { formattedDate, formattedTimeNoSeconds } from '../components/common/utils/date-time-conversions';

const { width, height } = Dimensions.get('window');

const ChargeHistoryCard = (props:
    { 
        index: number,
        chargerHistory: ChargerHistoryAttributes,
        charger: ChargerAttributes,
    }) => {

        /**
         * Converts a number of seconds into hh:mm format.
         * @param seconds - The number of seconds to convert.
         * @returns A string in hh:mm format followed by "hours".
         */
        const convertSecondsToHHMM = (seconds: number | null): string => {
            if (seconds){
                const hours = Math.floor(seconds / 3600);
                const minutes = Math.floor((seconds % 3600) / 60);
            
                // Format minutes to be two digits, e.g., 01 instead of 1
                const formattedMinutes = minutes.toString().padStart(2, '0');
            
                return `${hours}:${formattedMinutes} hours`;
            } else {
                return `Not Found`;
            }
        };

    // console.log(props.chargerHistory);
    // console.log(props.charger);
    
    return(
        <React.Fragment>
            <View className='mt-4 rounded-lg' key={`chargeHistoryCard${props.index}`} style={{backgroundColor: '#292D33'}}>
                <View className='flex flex-row items-center justify-between p-3'>
                    <View className='flex flex-row items-center gap-4'>
                        <Image
                            source={require('../../assets/chargerStation.png')}
                            style={{width: 50, height: 50}}
                        />
                        <View>
                            <Text className="text-base font-bold text-white">{props.charger?.name}</Text>
                            <Text
                                style={styles.textColor}
                                className="text-xs font-medium"
                                numberOfLines={1}             // Ensures a single line of text
                                ellipsizeMode="tail"          // Adds '...' if the text is too long
                            >
                                {props.charger?.full_address}
                            </Text>
                        </View>
                    </View>
                    {/* <View className='flex flex-row items-center gap-1'> */}
                    <View className='flex flex-col items-end gap-1' style={{ marginRight: 10 }}>
                        <Text className='text-xs font-bold text-white'>
                            {formattedDate(props.chargerHistory?.charging_start_time)}
                        </Text>
                        <Text style={[styles.textColor, {fontStyle: 'italic'}]} className='text-xs font-medium'>
                            {formattedTimeNoSeconds(props.chargerHistory?.charging_start_time)}
                        </Text>
                    </View>
                </View>
                <View className='flex flex-row items-center justify-between p-3'>
                    <View className='flex flex-row gap-5'>
                        <View>
                            <Text className='text-xs font-normal' style={styles.textColor1}>Total time</Text>
                            <Text className='text-sm font-semibold' style={styles.textColor2}>{`${convertSecondsToHHMM(props.chargerHistory?.total_charge_time)}`}</Text>
                        </View>
                        <View>
                            <Text className='text-xs font-normal' style={styles.textColor1}>Total kWh Charged</Text>
                            <Text className='text-sm font-semibold' style={styles.textColor2}>{`${((props.chargerHistory?.total_kwh ?? 0) / 1000).toFixed(2)} kWh`}</Text>
                        </View>
                        <View>
                            <Text className='text-xs font-normal' style={styles.textColor1}>Total price</Text>
                            <Text className='text-sm font-semibold' style={styles.textColor2}>{`$ ${props.chargerHistory?.total_charge_price?.toFixed(2)}`}</Text>
                        </View>
                    </View>
                    <View>
                        <MaterialIcons name="keyboard-arrow-right" size={24} color="white" />
                    </View>
                </View>
            </View>
        </React.Fragment>
    );
}

const styles = StyleSheet.create({
    safeArea: {
        flex: 1,
        backgroundColor: '#0B1E1D',
      },
    containerBgColor: {
        backgroundColor: '#1A1D21'
    },
    searchIcon: {
        backgroundColor: '#39BEB7',
        borderRadius: 50,
        paddingVertical: 12,
        paddingHorizontal: 13,
        marginRight: 8
    },
    inputContainer: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        backgroundColor: 'white',
        borderRadius: 25,
        paddingHorizontal: 20,
    },
    input: {
        flex: 1,
        paddingVertical: 10,
        outlineStyle: 'none'
      },
      filterIcon: {
        height: 19,
        width: 19,
        position: 'absolute',
        right: 10,
        top: -9
      },
    BgColor: {
        backgroundColor: '#51B868'
    },
    BgColor1: {
        backgroundColor: '#F97777'
    },
    textColor: {
        color: '#8D8E90'
    },
    textColor1: {
        color: '#E8E8E9',
    },
    textColor2: {
        color:'#39BEB7'
    },
    alignEllipses: {
        fontWeight: '500',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        maxWidth: 150,
    },
    centeredView: {
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
        marginTop: 22,
      },
    modalView: {
        margin: 20,
        backgroundColor: "#292D33",
        borderRadius: 30,
        padding: 10,
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 4,
        elevation: 5,
        width: 290,
        height: 250,
    },
    dropdownTrigger: {
        width: width <= 430 ? width*0.4 : 150,
    },
    dropdownContainer: {
        position: 'absolute',
        top: 30,
        left: 0,
        backgroundColor: '#fff',
        borderRadius: 5,
        marginTop: 2,
        width: width <= 430 ? width * 0.4 : 150,
        zIndex: 1000,
    },
    line: {
        borderBottomColor: "#636363",
        borderBottomWidth: 1,
        borderStyle: 'dashed',
        marginVertical: 20,
    },
    BgColor3: {
        backgroundColor: '#39BEB7'
    },
    BgColor4:{
        backgroundColor: '#636363'
    },
    backSpace: {
        backgroundColor: '#39BEB7',
        borderRadius: 50,
        paddingVertical: 13,
        paddingHorizontal: 15,
        marginRight: 8,
        marginLeft: 8,
        marginTop: 7
    },
    alignCrossIcon : {
        marginLeft:5, 
        marginTop:2
    }
})

export default ChargeHistoryCard;