export type OrderAttributes = {
    id: string;
    chargerId: string;
    userId: string;
    status: string;
    email: string;
}

export const createEmptyOrder = (overrides?: Partial<OrderAttributes>): OrderAttributes => ({
    id: '',
    chargerId: '',
    userId: '',
    status: '',
    email: '',
    ...overrides
})