import { PrepareApiProcedure } from "../../components/common/utils/prepare-api-procedure";
import { fetchDataFromAPI } from "../../services/api-requests";
import { LoginRegister, UserAttributes } from "../../models/login-register-model";
import { UserSessionAttributes } from "../../models/user-session-model";
import { notificationService } from "../../services/notification-service";
import AsyncStorage from '@react-native-async-storage/async-storage';
import { throwIfEmpty } from "rxjs";
import { UserInfo } from "../../models/user-info";
import { userActions } from "../slices/user-slice"
import { storeUserSession } from "../../services/user-session-service";
import { AppDispatch, RootState } from "..";
import { isEqual } from 'lodash';

export const validateUserToken = async () => {
  
  try {
    //const jwtToken = await AsyncStorage.getItem("token");
    const apiQuery = PrepareApiProcedure("user/validate", "GET", "validate", '');
    const response = await fetchDataFromAPI(apiQuery);
    //console.log("validated token resp: ", response);
    return response;
  } catch (error) {
    console.error('Error validating user: ', error);
  }
}

// Login User
export const loginUser = async (userLogin: UserSessionAttributes) => {
    try {

      const apiQuery = PrepareApiProcedure("login", "POST", "login", userLogin);
      const { loginType } = userLogin;
      //console.log(`sending query:`, apiQuery);

      //console.log(`to: ${process.env.REACT_NATIVE_API_BASE_URL + apiQuery.api}`)
      //console.log("userLogin obj:", userLogin);

      const userInfoQuery = PrepareApiProcedure("user/info", "GET", "user", '');
      if(userLogin.access_token !== ""){
        //get the user object here
        const userObj = await fetchDataFromAPI(userInfoQuery);
        return userObj;
      }

        const response = await fetch(
          process.env.REACT_NATIVE_API_BASE_URL + apiQuery.api,
          {
            method: apiQuery.request.action,
            headers: {
              "Accept": "*/*",
              "Content-Type": "application/json",
            },
            body:
              apiQuery.request.data === ""
                ? null
                : JSON.stringify(apiQuery.request.data),
          }
        );

      if (!response.ok) {
        const errorData = await response.json();
        if (errorData.statusNum === "3003") {
          /* do something */
        } else {
          if(loginType === false){
            console.log(errorData);
            errorData.message === "Password is incorrect!" ?
              notificationService.sendNotification("error", `Password is incorrect`) :
              errorData.message === "Email id is incorrect!" ? notificationService.sendNotification("error", `Email id is incorrect!`)
                : notificationService.sendNotification("error", `Invalid Credentials`)
          } else {
            notificationService.sendNotification("error", errorData.error);
          }
        }
        return null;
      }
      //this is now the login data
      const data = await response.json();

      //console.log("received login information:", data);

      //clear the AsyncStorage

      const keys = await AsyncStorage.getAllKeys();
      await AsyncStorage.multiRemove(keys);

      if(data.message === "Maximum number of devices used!"){
        notificationService.sendNotification("error", `Maximum number of devices used!`);
      } else if (userLogin.role === 'guest') {
        notificationService.sendNotification("success", `Please consider signing up for an account :)`);
      } else if (data) {
        notificationService.sendNotification("success", `Login Successful`);
      } else {
        notificationService.sendNotification("error", `Invalid Credentials`);
      }

      // data.message === "Maximum number of devices used!" ?
      // notificationService.sendNotification("error", `Maximum number of devices used!`) : data
      //   ? notificationService.sendNotification("success", `Login Successfully`)
      //   : notificationService.sendNotification("error", `Invalid Credentials`);
  
          const IpAddress = data.IpAddress;
          const access_token = data.access_token;
          const email = data.email;
          const role = data.role;
          // const userName = `${data.first_name} ${data.last_name}`;
          // const userId = JSON.stringify(data.id);
          
          await AsyncStorage.setItem("token", access_token);
          await AsyncStorage.setItem("IpAddress", IpAddress);
          await AsyncStorage.setItem("email", email);
          await AsyncStorage.setItem("role", role);
          await AsyncStorage.setItem("userId", data.id);
          await storeUserSession(data);

          //now grab the user data
          //const loginObj = await fetchDataFromAPI(userInfoQuery);

          //await AsyncStorage.setItem("userName", userName);
          
          //const userObj = await fetchDataFromAPI(userInfoQuery);
          //return userObj;

          return data;
        
    } catch (e) {
      console.error(e);
    }
  };

//register user
export const registerUser = async (userRegistration: UserAttributes) => {
  try {

    const apiQuery = PrepareApiProcedure("register", "POST", "register", userRegistration);
    //console.log("sending request to", process.env.REACT_NATIVE_API_BASE_URL + apiQuery.api)
    const response = await fetch(
      process.env.REACT_NATIVE_API_BASE_URL + apiQuery.api,
      {
        method: apiQuery.request.action,
        headers: {
          "Accept": "*/*",
          "Content-Type": "application/json",
        },
        body:
          apiQuery.request.data === ""
            ? null
            : JSON.stringify(apiQuery.request.data),
      }
    );

    if (!response.ok) {
      const errorData = await response.json();
      if (errorData.statusNum === "3003") {
        /* do something */
      } else {
        console.log(errorData);
      }
      return null;
    }

    const data = await response.json();
    //console.log(data);
    return [data];

  } catch (e) {
    console.error(e);
  }
}

export const oauthLogin = (profile: UserInfo) => {
  return async (dispatch: AppDispatch) => {
    //should we be checking the server for registration here?
    // or grabbing the server registration?

    dispatch(userActions.loginUser(
      {
        profile: profile
      }
    ));
  }
}

// update the user's profile
export const updateUser = (sessionObj: UserSessionAttributes, first_name?: string, last_name?: string, email?: string, password?: string) => {
  return async (dispatch: AppDispatch): Promise<boolean> => {
    try {
      const accountInfo = { first_name, last_name, email, password };

      // Filter out any undefined values
      const filteredAccountInfo = Object.fromEntries(
        Object.entries(accountInfo).filter(([_, value]) => value !== undefined)
      );

      const apiQuery = PrepareApiProcedure("user/update", "PUT", "user", filteredAccountInfo);
      const response = await fetchDataFromAPI(apiQuery);
      if(response.message === "User and Session updated successfully") {
        //update the user session
        dispatch(userActions.setUserSession(
          {
            session: {...sessionObj, ...filteredAccountInfo}
          }
        ));
        return true;
      } else {
        notificationService.sendNotification('error', response);
      }
      return false;
    } catch (error) {
      console.error(error);
      return false;
    }
  }
}

export const getActiveCharges = () => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      const apiQuery = PrepareApiProcedure("user/getActiveCharges", "GET", "user", '');
      const response = await fetchDataFromAPI(apiQuery);
      //console.log("got active charge response: ", response);

      // Get the current activeCharges from the Redux store
      const currentActiveCharges = getState().user.activeCharges;

      // Check if the response is an empty array
      if (Array.isArray(response) && response.length === 0) {
        // Dispatch to clear the activeCharges if the response is an empty array
        dispatch(userActions.clearActiveCharges());
      } else if (Array.isArray(response)) {
        // Compare new response with the current activeCharges
        if (!isEqual(currentActiveCharges, response)) {
          // If there is a difference, dispatch to set activeCharges in the store
          dispatch(userActions.setActiveCharges({ activeCharges: response }));
        }
      } else {
        // Handle unexpected response types (e.g., errors or non-array responses)
        console.error("Unexpected response format:", response);
      }
    } catch (error) {
      console.error("Failed to fetch active charges:", error);
    }
  };
};