import React, { useState } from 'react';
import { View, Text, Pressable, StyleSheet, TextInput, SafeAreaView, Dimensions } from 'react-native';
import { ScrollView } from 'react-native-gesture-handler';
import { createUser } from '../models/login-register-model';
import { registerUser } from '../store/actions/user-actions';
import { notificationService } from '../services/notification-service';
import { IconButton } from 'react-native-paper';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import { RootStackParamList } from '../components/MeanderStack';
import CreateAccountForm from './CreateAccountForm';

const { width, height } = Dimensions.get('window');

type Props = NativeStackScreenProps<RootStackParamList, 'CreateAccount'>

const CreateAccount: React.FC<Props> = ({navigation, route}) => {
    return (
        <view>
            <CreateAccountForm 
                formEmail={route?.params?.formEmail || ''} 
                navigation={navigation}    
            />
        </view>
    )
};

export default CreateAccount;

