import { PrepareApiProcedure } from "../components/common/utils/prepare-api-procedure";
import { OrderAttributes } from "../models/order-model";
import { fetchDataFromAPI } from "./api-requests";

export const createOrder = async (chargerId: string): Promise<OrderAttributes | null> => {
  try {
    const apiPaymentMethodsQuery = PrepareApiProcedure(
      `orders`,
      `POST`,
      'order',
      { chargerId: chargerId }
    )
    return await fetchDataFromAPI(apiPaymentMethodsQuery)

  } catch(e) {
    console.error(e);
    return null
  }
}

export const updateOrderEmail = async (orderId: string, email: string): Promise<OrderAttributes | null> => {
  try {
    const apiQuery = PrepareApiProcedure(
      `orders/updateEmail`,
      `PUT`,
      `order`,
      {
        orderId: orderId,
        email: email
      }
    );
    return await fetchDataFromAPI(apiQuery);
  } catch (error) {
    console.error(error);
    return null;
  }
}

export const cancelOrder = async (orderId: string): Promise<OrderAttributes | null> => {
  try{
    const apiQuery = PrepareApiProcedure(
      'orders/cancelOrder',
      'PUT',
      'order',
      {
        orderId: orderId,
      }
    );
    return await fetchDataFromAPI(apiQuery);
  } catch (error) {
    console.error(error);
    return null;
  }
}