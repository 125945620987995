import { platform } from "process";
import React, { useState, useEffect, useContext } from "react";
import {
  Text,
  View,
  Image,
  Pressable,
  StyleSheet,
  Platform,
  Dimensions,
  SafeAreaView,
} from "react-native";
import { useNavigation } from "@react-navigation/native";
import { UserSessionAttributes } from "../models/user-session-model";
import { useSelector } from "react-redux";
import { RootState } from "../store";

const { width, height } = Dimensions.get("window");
const ICON_HEIGHT = 25;
const ICON_WIDTH = 25;


const Footer = (props: any ) => {

  const [state, setState] = useState({
    pump: props?.currentRouteName === 'MyChargers' ? true : false,
    car: props?.currentRouteName === 'ChargerSelection' ? true : false,
    profile: props?.currentRouteName === 'MyAccount' ? true : false,
  });
  const session: UserSessionAttributes | undefined = useSelector((state: RootState) => state.user.session);
  const [tintColor, setTintColor] = useState<string | undefined>(undefined);
  
  const navigation = useNavigation();

  const selectIcon = (icon: any) => {
    if (icon === "pump") {
      setState({ ...state, pump: true, car: false, profile: false });
    } else if (icon === "car") {
      setState({ ...state, pump: false, car: true, profile: false });
    } else {
      setState({ ...state, pump: false, car: false, profile: true });
    }
  };

  useEffect(() => {
    const userTint = session?.role === 'guest' ? 'lightgray' : undefined;
    setTintColor(userTint);
  }, [session])

    return (
      <SafeAreaView>
      <View
        style={[
          footerStyle.containerColor,
          footerStyle.container,
          {  
            width: width < 430 ? width : 430,
          },
        ]}
      >
        <Pressable
          onPress={() => {
            selectIcon("car"); 
            navigation.navigate('ChargerSelection', {tabSelect: 'Map'})
          }}
        >
          {Platform.OS === "web" ? (
            <View style={footerStyle.footerText}>
              <View style={footerStyle.iconContainer}>
              {state.car === true ? (
                <Image
                  source={require("../images/carHighlighted.png")}
                  resizeMode="cover"
                  style={{
                    height: 22,
                    width: 25,
                  }}
                />
              ) : (
                <Image
                  source={require("../images/carNonHightighted.png")}
                  resizeMode="cover"
                  style={{
                    height: 22,
                    width: 25,
                  }}
                />
              )}
              </View>
              <Text style={footerStyle.textColorEnabled}>Search</Text>
            </View>
          ) : (
            <View>
              
              {state.car === true ? (
                <Image
                  source={require("../images/carHighlighted.png")}
                  resizeMode="cover"
                />
              ) : (
                <Image
                  source={require("../images/carNonHightighted.png")}
                  resizeMode="cover"
                />
              )}
            </View>
          )}
        </Pressable>

        <Pressable
          disabled={session && session.role === 'guest'}
          onPress={() => {
            selectIcon("pump");
            navigation.navigate('MyChargers')
          }}
        >
          {Platform.OS === "web" ? (
            <View style={footerStyle.footerText}>
              <View style={footerStyle.iconContainer}>
              {state.pump === true ? (
                <Image
                  source={require("../images/pumpHighlighted.png")}
                  resizeMode="cover"
                  style={{
                    height: 25,
                    width: 20,
                  }}
                />
              ) : (
                <Image
                  source={require("../images/pumpNonHighlighted.png")}
                  resizeMode="cover"
                  style={[{
                    height: 25,
                    width: 20,
                  },
                    tintColor ? { tintColor } : null
                  ]}
                />
              )}
              </View>
              <Text style={footerStyle.textColorEnabled}>My Chargers</Text>
            </View>
          ) : (
            <View>
              {state.pump === true ? (
                <Image
                  source={require("../images/pumpHighlighted.png")}
                  resizeMode="cover"
                />
              ) : (
                <Image
                  source={require("../images/pumpNonHighlighted.png")}
                  resizeMode="cover"
                />
              )}
            </View>
          )}
        </Pressable>

        <Pressable
          onPress={() => {
            selectIcon("profile");
            navigation.navigate('MyAccount')
          }}
        >
          {Platform.OS === "web" ? (
            <View style={footerStyle.footerText}>
              <View style={footerStyle.iconContainer}>
              {state.profile === true ? (
                <Image
                  source={require("../images/profileHighlighted.png")}
                  resizeMode="cover"
                  style={{
                    height: 25,
                    width: 20,
                  }}
                />
              ) : (
                <Image
                  source={require("../images/profileNonHighlighted.png")}
                  resizeMode="cover"
                  style={{
                    height: 25,
                    width: 20,
                  }}
                />
              )}
              </View>
              <Text style={footerStyle.textColorEnabled}>Menu</Text>
            </View>
          ) : (
            <View>
              {state.profile === true ? (
                <Image
                  source={require("../images/profileHighlighted.png")}
                  resizeMode="cover"
                />
              ) : (
                <Image
                  source={require("../images/profileNonHighlighted.png")}
                  resizeMode="cover"
                />
              )}
            </View>
          )}
        </Pressable>
      </View>
      </SafeAreaView>
    );
  }

export default Footer;

const footerStyle = StyleSheet.create({
  container: {
    marginLeft: "auto",
    marginRight: "auto",
    textAlign: "center",
    marginTop: "auto",
    marginBottom: "auto",
    height: height * 0.07
  },
  containerColor: {
    backgroundColor: "#FAFAFA",
    width: "100%",
    padding: 24,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
  },
  textColorEnabled: {
    color: "gray",
  },
  textColorDisabled: {
    color: "lightgray",
  },
  footerText: {
    alignContent: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column'
  },
  iconStyle: {
    width: ICON_WIDTH,
    height: ICON_HEIGHT,
  },
  iconContainer: {
    width: ICON_WIDTH,
    height: ICON_HEIGHT,
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: -10,
  },
});
