import AsyncStorage from '@react-native-async-storage/async-storage';

export const fetchDataFromAPI = async (apiQuery: any) => {
    try{
        const jwtToken = await AsyncStorage.getItem("token");
        const IpAddress = await AsyncStorage.getItem("IpAddress");
        const email = await AsyncStorage.getItem("email");

        const response = await fetch(process.env.REACT_NATIVE_API_BASE_URL + apiQuery.api,
            {
                method: apiQuery.request.action,
                headers:{
                    'Accept': 'application/json, text/plain',
                    'Content-Type': 'application/json',
                    'x-username': `${email}`,
                    'x-ip-address':`${IpAddress}`,
                    'authorization': 'Bearer ' + jwtToken
                },
                //credentials: 'include',
                body: (apiQuery.request.data === '') ? null : JSON.stringify(apiQuery.request.data)
            });
        
        if(!response.ok){
            const errorData = await response.json();

            /* Check the error status code / message
            * and verify if we need to suppress or not
            */
            if ((response.status === 3003)){
                /* do something */
                return errorData;
            } else {
                //send notification to browser via notification service
                return {...errorData, errStatus:response.status};
            }
        }

        // Check if the response has a body
        const contentType = response.headers.get('content-type');
        let data;

        if (contentType && contentType.includes('application/json')) {
            try {
                data = await response.json();
            } catch (err) {
                console.error('Error parsing JSON response:', err);
                throw new Error('Failed to parse JSON');
            }
        } else {
            // Handle cases where the response is not JSON or is empty
            data = await response.text();
            if (!data) {
                console.error('Empty response or non-JSON response');
                return null; // or handle this case as you see fit
            }
        }

        return data;
    } catch (err) {
        console.error('Error in fetchDataFromAPI:', err);
        throw err;
    }
}