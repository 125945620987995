export const getCurrentUtcSeconds = (): number => {
    const currentDate = new Date();
    const utcSeconds = Math.floor(currentDate.getTime() / 1000);
    return utcSeconds;
}

export const getPastUtcSeconds = (rollBackSeconds: number): number => {
    //rollback the current time by a number of seconds
    const currentDate: Date = new Date();
    const rollbackDate: Date = new Date(currentDate.getTime() - (rollBackSeconds * 1000));

    const utcSeconds: number = Math.floor(rollbackDate.getTime() / 1000);

    return utcSeconds;
}

export const getDateInUtcSeconds = (d:Date): number => {
    const utcSeconds = Math.floor(d.getTime() / 1000);
    return utcSeconds;
}

export const getDateFromUtcSeconds = (utcSeconds: number): Date => {
    const milliseconds = utcSeconds * 1000;
    return new Date(milliseconds);
}

// FUNCTION: getDateString
// PURPOSE: Return date in MM/DD/YYYY format
export const getDateString = (d: Date): string => {
    const pad = (n: number) => n < 10 ? '0' + n : n;
    const month = pad(d.getMonth() + 1); // getMonth() is zero-based
    const day = pad(d.getDate());
    const year = d.getFullYear();
    const formattedDate = `${month}/${day}/${year}`
    return formattedDate;
}

//FUNCTION: getTimeString
// PURPOSE: return time in HH:MM:SS format
export const getTimeString = (d: Date): string => {
    const pad = (n: number) => n < 10 ? '0' + n : n;

    let hours = d.getHours();
    const minutes = pad(d.getMinutes());
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'

    const formattedTime = `${pad(hours)}:${minutes}${ampm}`;
    return formattedTime;
};

export const getCurrentDate = () => {
    const today = new Date();
    const dd = String(today.getDate()).padStart(2, '0');
    const mm = String(today.getMonth() + 1).padStart(2, '0'); // January is 0!
    const yyyy = today.getFullYear();

    return `${mm}/${dd}/${yyyy}`;
};

export const getOneMonthBackDate = () => {
    const today = new Date();
    const oneMonthBack = new Date(today.setMonth(today.getMonth() - 1));
    const dd = String(oneMonthBack.getDate()).padStart(2, '0');
    const mm = String(oneMonthBack.getMonth() + 1).padStart(2, '0'); // January is 0!
    const yyyy = oneMonthBack.getFullYear();

    return `${mm}/${dd}/${yyyy}`;
};

// Format the date to mm/dd/yyyy
// Input format is 2024-05-20T21:46:52.000Z
export const formattedDate = (inputDateTimeStr: string | null) => {
    if (inputDateTimeStr !== null){
        const inputDate = new Date(inputDateTimeStr);
        return(
            inputDate.toLocaleDateString('en-US', 
                {
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit',
                })
            )
    }
}

// Format the time to HH:MM
// Input format is 2024-05-20T21:46:52.000Z
export const formattedTimeNoSeconds = (inputDateTimeStr: string | null) => {

    if (inputDateTimeStr !== null){
        const inputDateTime = new Date(inputDateTimeStr);
        const formattedTime = inputDateTime.getHours().toString().padStart(2, '0') + ':' + inputDateTime.getMinutes().toString().padStart(2, '0');
        return formattedTime;

        //using toLocaleDateString (this returns the whole date)
        // return(
        //     inputDateTime.toLocaleDateString('en-US', 
        //         {
        //             hour: '2-digit',
        //             minute: '2-digit',
        //             hour12: false,
        //         })
        // )
    }

}
