export interface UserInfo {
    service: string;
    sub: number;
    email: string;
    firstname: string;
    lastname: string;
    picture: string;
}

export const createEmptyUser = (overrides?: Partial<UserInfo>): UserInfo => ({
    service: '',
    sub: 0,
    email: '',
    firstname: '',
    lastname: '',
    picture: '',
    ...overrides
})

export const sampleUsers:UserInfo[] = [
    {
        service: 'meander',
        sub: 1,
        email: 'andrew@gmail.com',
        firstname: 'Andrew',
        lastname: 'March',
        picture: 'pictureLink',
    },
    {
        service: 'google',
        sub: 2,
        email: 'tom@gmail.com',
        firstname: 'Tom',
        lastname: 'Schoenherr',
        picture: 'pictureLink',
    },
    {
        service: 'apple',
        sub: 3,
        email: 'jeremy@gmail.com',
        firstname: 'Jeremy',
        lastname: 'Clayton',
        picture: 'pictureLink',
    }
]