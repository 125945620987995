import React from 'react';
import { Platform } from 'react-native';
import { notificationService } from '../../../services/notification-service';

// Get IpAddress
export const getIpAddressFunc = async (): Promise<string | null> => {
    const appVersion = process.env.REACT_NATIVE_APP_VERSION;
    const reactNativeVersion = Platform.Version || "Unknown";
    const userAgent = `MeanderEvApp/${appVersion} (ReactNative/${reactNativeVersion})`;
    const host = "meanderev.com"; // Replace this with your actual API host
  
    try {
      const response = await fetch(`https://ipapi.co/json`, {
        method: "GET",
        headers: {
          Accept: "*/*",
          "Content-Type": "application/json",
          "User-Agent": userAgent,
          //"Host": host,
        },
      });
  
      if (!response.ok) {
        const errorData = await response.json();
        if (errorData.statusNum === "3003") {
          /* do something */
        } else {
          notificationService.sendNotification(
            "error",
            `Cannot retrieve IP Address`
          );
        }
        return null;
      }
  
      const data = await response.json();
      //console.log("getting IP address", data);
  
      const IpSend = data.ip;
      return IpSend;
    } catch (e) {
      console.log("Error trying to retrieve IP address:", e);
      return null;
    }
  };