import React from "react";
import { View, Text, StyleSheet, Modal, Pressable } from "react-native";
import { Button, Provider as PaperProvider } from 'react-native-paper';
import DateTimePicker from 'react-native-ui-datepicker';
import { SafeAreaProvider } from "react-native-safe-area-context";
import { getCurrentDate } from "./common/utils/date-time-conversions";
import dayjs from "dayjs";

const formatDate = (date: dayjs.Dayjs) => {
  const dayjsDate = dayjs(date);

  if (!dayjsDate.isValid()) {
    throw new Error("Invalid date passed to formatDate");
}
  
  const dd = String(dayjsDate.date()).padStart(2, '0');
  const mm = String(dayjsDate.month() + 1).padStart(2, '0'); // January is 0!
  const yyyy = dayjsDate.year();

  return `${mm}/${dd}/${yyyy}`;
};


export const DatePicker = (props: {
  customDatePicker: (date:any) => void,
  prev?: number,
  initialDate?: string | null,
  editable?: boolean
}) => {
  const [date, setDate] = React.useState(dayjs().subtract(props.prev ?? 0, 'month'));
  const [open, setOpen] = React.useState(false);
  const [showDate, setShowDate] = React.useState('');

  const todayDate = getCurrentDate();

  const onDismissSingle = React.useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const onConfirmSingle = React.useCallback(
    (params) => {
        setOpen(false);
        const newDate = params.date.$d;
        setDate(newDate);
        const formattedDate = formatDate(newDate);
        props.customDatePicker(formattedDate);
    },
    [setOpen, setDate]
  );

  return (
    <SafeAreaProvider>
      <View style={styles.container}>
        <Button 
          onPress={() => props.editable && setOpen(!open)}
          uppercase={false} 
          mode="outlined" 
          style={styles.button}
        >
          {formatDate(date)}
        </Button>
        {props.editable && (
        <Modal
          transparent={true}
          animationType="none"
          visible={open}
          onRequestClose={() => setOpen(false)}
        >
            <View style={styles.modalContainer}>
              <View style={styles.pickerWrapper}>
                <DateTimePicker
                  mode="single"
                  date={date}
                  onChange={(selectedDate) => onConfirmSingle(selectedDate)}
                />
                <Pressable 
                  onPress={() => setOpen(false)} 
                  className="px-4 py-2 text-xs font-semibold rounded-full" 
                  style={styles.BgColor}>
                    <Text className="text-white">
                      Done
                    </Text>
                </Pressable>
              </View>
          </View>
        </Modal>
        )}
      </View>
    </SafeAreaProvider>
  );
};

export default DatePicker;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  button: {
    backgroundColor: '#0B1E1D',
  },
  BgColor: {
    backgroundColor: '#39BEB7',
    color: '#fff',
  },
  pickerWrapper: {
    backgroundColor: 'white',
    padding: 20,
    borderRadius: 10,
    alignItems: 'center',
    width: 300,
    maxWidth: 300,
    elevation: 5,
  },
  modalContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
});