// export interface UserSessionAttributes {
//     UserId: string;
//     first_name: string;
//     last_name: string;
//     email: string;
//     IpAddress: string;
//     token: string;
//     password: string;
//     count: number;
//     is_active: number;
//     loginType: boolean;
//   }

  // export const createUserSession = (overrides?: Partial<UserSessionAttributes>): UserSessionAttributes => ({
  //   UserId: '',
  //   first_name: '',
  //   last_name: '',
  //   email: '',
  //   IpAddress: '',
  //   token: '',
  //   password: '',
  //   count: 0,
  //   loginType: false,
  //   is_active: 0,
  //   ...overrides,
  // });

export interface UserSessionAttributes {
  message: string;
  access_token: string;
  id: string; //user id
  first_name: string;
  last_name: string;
  email: string;
  IpAddress: string | null;
  signature: string;
  scope: string;
  instance_url: string;
  token_type: string;
  issued_at: string;
  expires_at: string;
  primary_use: string;
  role: string;
  password: string;
  loginType: boolean;
}

export const createUserSession = (overrides?: Partial<UserSessionAttributes>): UserSessionAttributes => ({
  message: '',
  access_token: '',
  id: '', //user id
  first_name: '',
  last_name: '',
  email: '',
  IpAddress: '1.1.1.1',
  signature: '',
  scope: '',
  instance_url: '',
  token_type: '',
  issued_at: '',
  expires_at: '',
  primary_use: '',
  role: '',
  password: '',
  loginType: true,
  ...overrides,
});

