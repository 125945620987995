import React, {useEffect, useState} from "react";
import {
    SafeAreaView,
    View,
    Text,
    StyleSheet,
    Pressable
  } from "react-native";
import { FontAwesome6 } from '@expo/vector-icons';
import { WeekConfiguration, createEmptyWeek, dayNames, generatePeakObj, setPeak } from "../components/common/utils/peak-off-peak-field";
import DropdownComponentEnd from "../components/DropdownComponentEnd";
import DropdownComponentStart from "../components/DropdownComponentStart";

const RegisterChargerCheckbox = ({
    setPeakSelection, 
    checkboxSelected, 
    generatePeakObjCheckbox, 
    setSelectedDays,
    editChargerStatus}) => {
    const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
    const [weekView, setWeekView] = useState<WeekConfiguration>(createEmptyWeek());
    
    const handleOptionSelect = (day: string) => {
        const index = selectedOptions.indexOf(day);

        if (index === -1) {
            setSelectedOptions([...selectedOptions, day]);
        } else {
            const updatedOptions = [...selectedOptions];
            updatedOptions.splice(index, 1);
            setSelectedOptions(updatedOptions);
        }

        if(day && index !== -1){
            setWeekView({
                ...weekView,
                [day]: {
                    start: 0,
                    end: 0
                }
            })
        }
    };

    useEffect(()=> {
        
        if(editChargerStatus === true){
            setWeekView(generatePeakObjCheckbox)
            
            if(checkboxSelected?.updatedDay){
                setSelectedOptions(checkboxSelected?.updatedDay);
            }
        }

    }, [generatePeakObjCheckbox, checkboxSelected, editChargerStatus])

    useEffect(() => {
        setPeakSelection(weekView);
    },[selectedOptions, weekView])

    const handleTimeSelect = (sel: any, day: string, type: 'start' | 'end') => {
        
        if (selectedOptions.find(selectedDay => selectedDay === day)) {
            setWeekView({
                ...weekView,
                [day]: {
                    start: type === 'start' ? sel.value : weekView[day].start,
                    end: type === 'end' ? sel.value : weekView[day].end
                }
            })
        }
    }


    const options = [
        { value: 0, label: '12 AM' },
        { value: 1, label: '1 AM' },
        { value: 2, label: '2 AM' },
        { value: 3, label: '3 AM' },
        { value: 4, label: '4 AM' },
        { value: 5, label: '5 AM' },
        { value: 6, label: '6 AM' },
        { value: 7, label: '7 AM' },
        { value: 8, label: '8 AM' },
        { value: 9, label: '9 AM' },
        { value: 10, label: '10 AM' },
        { value: 11, label: '11 AM' },
        { value: 12, label: '12 PM' },
        { value: 13, label: '1 PM' },
        { value: 14, label: '2 PM' },
        { value: 15, label: '3 PM' },
        { value: 16, label: '4 PM' },
        { value: 17, label: '5 PM' },
        { value: 18, label: '6 PM' },
        { value: 19, label: '7 PM' },
        { value: 20, label: '8 PM' },
        { value: 21, label: '9 PM' },
        { value: 22, label: '10 PM' },
        { value: 23, label: '11 PM' },
    ];

    const startStopSelection = Object.entries(weekView).map(([day, config], index) => {
        return(
            <View 
                key={`${day}StartStop`} 
                style={styles.row}
                className="mt-2"
            >
                <View key={day} style={styles.row} className="h-10 w-32">
                    <Pressable 
                        onPress={() => handleOptionSelect(day)}
                    >
                        <View style={styles.checkbox}>
                            {
                                selectedOptions.find(d => d === day) ? 
                                <View>                                    
                                    <FontAwesome6 name="check" size={17} color="white" />
                                </View>
                                : <View></View>
                            }
                        </View>
                    </Pressable>
                    <Text className="text-white text-base">{day}</Text>
                </View>

                <DropdownComponentStart
                    key={`${day}Start`}
                    data={options}
                    placeholderText={'start time'}
                    onItemSelected={(s) => handleTimeSelect(s, `${day}`, 'start')}
                    startVal={options[config.start].value}
                    hidden={selectedOptions.find(d => d === day) ? false : true}
                />
                <DropdownComponentEnd
                    key={`${day}Stop`}
                    data={options}
                    placeholderText={'end time'}
                    onItemSelected={(value) => handleTimeSelect(value, `${day}`, 'end')}
                    endVal={options[config.end].value}
                    hidden={selectedOptions.find(d => d === day) ? false : true}
                />
            </View>
        )
    });

    return(
        <View>
            <View className="flex flex-row">
                <View>
                    {startStopSelection}
                </View> 
            </View>
        </View>
        
    );
}

export default RegisterChargerCheckbox;

const styles = StyleSheet.create({
    safeArea: {
        flex: 1,
        backgroundColor: '#0B1E1D',
    },
    containerBgColor: {
        backgroundColor: '#0B1E1D',
    },
    BgColor1: {
        backgroundColor: '#292D33'
    },
    BgColor2: {
        backgroundColor: '#234D4E'
    },
    line: {
        borderBottomColor: "#636363",
        borderBottomWidth: 1,
        borderStyle: 'dashed',
        marginVertical: 20,
    },
    row: {
        flexDirection: 'row',
        alignItems: 'center',
        gap: 2
    },
    checkbox: {
        width: 20,
        height: 20,
        borderWidth: 1,
        borderColor: 'white',
        justifyContent: 'center',
        alignItems: 'center',
        marginRight: 10,
        borderRadius: 5,
    },
});