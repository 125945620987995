import { ChargerAttributes } from "../../../models/charger-model";
import { getDateFromUtcSeconds } from "./date-time-conversions";

// Type for the bit field
type HexWeek = string;

// Type for day ranges
export interface DayRange {
    start: number;
    end: number;
}

// Type for the week's configuration
export interface WeekConfiguration {
    [day: string]: DayRange;
}

export const dayNames = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

export const createEmptyWeek = (overrides?: Partial<WeekConfiguration>): WeekConfiguration => ({
    Sunday: {start: 0, end: 0},
    Monday: {start: 0, end: 0},
    Tuesday: {start: 0, end: 0},
    Wednesday: {start: 0, end: 0},
    Thursday: {start: 0, end: 0},
    Friday: {start: 0, end: 0},
    Saturday: {start: 0, end: 0},
    ...overrides,
})

// Function to set peak hours for multiple days
// The input to setPeak will be 
// {    
    // monday: {startTime: 13, endTime: 19}, 
    // tuesday: {startTime: 13, endTime: 19}, 
    // ... 
    // saturday:  {startTime: 0, endTime: 0}
    // sunday: {startTime: 0, endTime: 0}
// }
export const setPeak = (weekConfig: WeekConfiguration): HexWeek => {
    const numBlocks = 21; // 7 days * 3 blocks per day
    const view = new Uint8Array(numBlocks);

    dayNames.forEach((day, index) => {
        const { start, end } = weekConfig[day];
        if (start === end) return;  // Skip days with no peak hours.

        const dayBaseIndex = index * 3;
        const startBlock = Math.floor(start / 8);
        const endBlock = Math.floor(end / 8);

        for (let block = startBlock; block <= endBlock; block++) {
            const blockStartHour = block * 8;
            let mask = 0xFF; // Start with all bits set

            if (block == startBlock) {
                mask = 0xFF >> (start % 8); // Zero out bits before the start hour in the block
            }
            if (block == endBlock) {
                mask &= ~(0xFF >> ((end % 8) + 1));
            }

            view[dayBaseIndex + block] |= mask;
        }
    });

    const mappedBinary = [...view].map((n) => n.toString(16).padStart(2, "0")).join("");
    return mappedBinary;
}

export const checkIsPeak = (date: Date, weeklyHours: HexWeek): boolean => {
    const day = date.getDay();  // Get the day index, where 0 is Sunday
    const hour = date.getHours();  // Get the hour
    let binaryString = '';
    for (let i = 0; i < weeklyHours.length; i += 2) {
        const hexValue = weeklyHours.substring(i, i + 2);
        const binaryChunk = parseInt(hexValue, 16).toString(2).padStart(8, '0');
        binaryString += binaryChunk;
    }
    //console.log("Complete binary sequence:", binaryString);

    // Calculate the exact bit index for the current hour
    const bitIndex = day * 24 + hour; // 24 hours per day, 1 bit per hour
    //console.log("Bit index = ", bitIndex);

    // Determine if the specific hour is peak by checking the bit at the bitIndex
    return binaryString.charAt(bitIndex) === '1';
}

// Calculate peak and off-peak times between two dates
// calculatePeak Function: Calculates the total number of 
// seconds spent in peak and off-peak times between two 
// Date instances. It iterates over every hour (or part thereof) 
// between the start and end times, 
// using the checkIsPeak function to check each hour.
export const calculatePeak = (startDate: Date, endDate: Date, weeklyHours: HexWeek): { peakTime: number, offPeakTime: number } => {
    let peakSeconds = 0;
    let offPeakSeconds = 0;
    let currentDate = new Date(startDate);

    while (currentDate <= endDate) {
        const secondsToEndOfHour = 3600 - currentDate.getMinutes() * 60 - currentDate.getSeconds();
        const durationThisHour = Math.min(secondsToEndOfHour, (endDate.getTime() - currentDate.getTime()) / 1000);

        if (checkIsPeak(currentDate, weeklyHours)) {
            peakSeconds += durationThisHour;
            console.log(`Adding to peak: ${durationThisHour} seconds at ${currentDate}`);
        } else {
            offPeakSeconds += durationThisHour;
            console.log(`Adding to off-peak: ${durationThisHour} seconds at ${currentDate}`);
        }

        // Move to the next hour
        currentDate = new Date(currentDate.getTime() + secondsToEndOfHour * 1000);
        console.log("now using current date:", currentDate);
    }

    return {
        peakTime: peakSeconds, //in seconds
        offPeakTime: offPeakSeconds //in seconds
    };
}

export const generatePeakObj = (mappedBinary: HexWeek) => {
    const rawBuffer = new ArrayBuffer(mappedBinary.length / 2);
    const arrayedBinary = new Uint8Array(rawBuffer);

    // Parse the hex string into bytes
    for (let i = 0, j = 0; i < mappedBinary.length; i += 2, j++) {
        arrayedBinary[j] = parseInt(mappedBinary.substring(i, i + 2), 16);
    }

    const weekConfig: WeekConfiguration = {};

    dayNames.forEach((day, index) => {
        const dayStartIndex = index * 3; // Each day has 3 blocks
        let fullDayBinaryString = ''; // Initialize an empty string to concatenate all binary strings of the day
        let startHour = null;
        let endHour = null;

        // Concatenate the binary strings of all three blocks for the current day
        for (let block = 0; block < 3; block++) {
            const byte = arrayedBinary[dayStartIndex + block];
            const binaryString = byte.toString(2).padStart(8, '0');
            fullDayBinaryString += binaryString;
        }

        // Log the combined binary string for debugging
        //console.log(`Day: ${day}, Combined Binary: ${fullDayBinaryString}`);

        // Find the first and last occurrence of '1'
        const firstOneIndex = fullDayBinaryString.indexOf('1');
        const lastOneIndex = fullDayBinaryString.lastIndexOf('1');

        if (firstOneIndex !== -1) { // Check if there is at least one '1' in the string
            startHour = Math.floor(firstOneIndex / 8) * 8 + (firstOneIndex % 8); // Calculate start hour from the first '1'
            endHour = Math.floor(lastOneIndex / 8) * 8 + (lastOneIndex % 8);   // Calculate end hour from the last '1'
        }

        // Assign day configuration
        weekConfig[day] = {
            start: startHour !== null ? startHour : 0,
            end: (endHour !== null ? endHour : 0)  // Adjust end hour to be inclusive
        };
    });

    return weekConfig;
}

export const calculateTimeFromEmporiaOutput = (chargerHistory: any): { peakTime: number, offPeakTime: number } => {
    //contains an array "usage details"
    // SAMPLE OUTPUT:
    // "scale": "Minutes",
    // "usageDetails": [
    //     {
    //         "time": "1716807300",
    //         "usage": 46.032137189838615
    //     },
    //     {
    //         "time": "1716807360",
    //         "usage": 61.53501533709247
    //     }
    //     ...

    //cycle through the scale of usage details and add up the total number of seconds for each period
    let pt = 0;
    let opt = 0;
    console.log(chargerHistory.usageDetails.usageDetails[chargerHistory.usageDetails.usageDetails.length - 1]);
    const {peakTime, offPeakTime} = calculatePeak(getDateFromUtcSeconds(chargerHistory.usageDetails.usageDetails[0].time), getDateFromUtcSeconds(chargerHistory.usageDetails.usageDetails[chargerHistory.usageDetails.usageDetails.length - 1].time), chargerHistory.meanderData.peak_selection_basis);
    if(chargerHistory.usageDetails.scale === 'Minutes'){
        pt = peakTime + pt;
        opt = offPeakTime + opt;
        // chargerHistory.usageDetails.usageDetails.forEach((history) => {
        //     const {peakTime, offPeakTime} = calculatePeak(getDateFromUtcSeconds(history.time), getDateFromUtcSeconds(history.time + 60), chargerHistory.meanderData[0].peak_selection_basis)
        //     pt = peakTime + pt;
        //     opt = offPeakTime + opt;
        // })
    } else if (chargerHistory.usageDetails.scale === 'Seconds'){
        pt = peakTime + pt;
        opt = offPeakTime + opt;
        // chargerHistory.usageDetails.usageDetails.forEach((history) => {
        //     const {peakTime, offPeakTime} = calculatePeak(getDateFromUtcSeconds(history.time), getDateFromUtcSeconds(history.time), chargerHistory.meanderData[0].peak_selection_basis)
        //     pt = peakTime + pt;
        //     opt = offPeakTime + opt;
        // })
    } else if (chargerHistory.usageDetails.scale === 'FifteenMinutes'){
        //const {peakTime, offPeakTime} = calculatePeak(getDateFromUtcSeconds(chargerHistory.usageDetails.usageDetails[0].time), getDateFromUtcSeconds(chargerHistory.usageDetails.usageDetails[chargerHistory.usageDetails.usageDetails.length - 1].time), chargerHistory.meanderData.peak_selection_basis)
        pt = peakTime + pt;
        opt = offPeakTime + opt;
    }

    return({
        peakTime: pt,
        offPeakTime: opt
    });

}

