import React, { useEffect, useState } from 'react';

import { Modal, SafeAreaView, View,Text, ScrollView, StyleSheet, Dimensions, TextInput, Image, Pressable } from 'react-native';
import { Ionicons } from '@expo/vector-icons';
import { FontAwesome6 } from '@expo/vector-icons';
import { MaterialIcons } from '@expo/vector-icons';
import { useDispatch, useSelector } from 'react-redux';
import { ChargerAttributes } from '../models/charger-model';
import { RootState } from '../store';
import { getAllChargers, updateSelectedCharger } from '../store/actions/charging-actions';
import { Tooltip } from 'react-native-elements';
import { PrepareApiProcedure } from '../components/common/utils/prepare-api-procedure';
import { fetchDataFromAPI } from '../services/api-requests';
import DatePicker from "../components/DateTimePicker";
import { notificationService } from "../services/notification-service";
import { ChargerHistoryAttributes } from '../models/charger-history-model';
import ChargeHistoryCard from './ChargeHistoryCard';
import { getChargingHistoryByUser, getMyChargerHistory } from '../services/charger-history-service';
import { AppDispatch } from '../store';
import { getSingleChargerData } from '../services/charger-service';

const { width, height } = Dimensions.get('window');

const TransactionHistory = ({ navigation }) => {
    const chargers: ChargerAttributes[] = useSelector((state: RootState) => state.chargers.chargers)
    const[chargingHistory, setChargingHistory] = useState<ChargerHistoryAttributes[]>([]);
    const[chargerAssociation, setChargerAssociation] = useState<ChargerAttributes[]>([])

    const [modalVisible, setModalVisible] = useState(false);

    const [uuidInfo, setUuid] = useState<String>('');
    const [query, setQuery] = useState('');

    const [customDatePickerFirst, SetCustomDatePickerFirst] = useState('');
    const [customDatePickerSecond, SetCustomDatePickerSecond] = useState('');

    const [filteredCharger, setFilteredCharger] = useState();

    const dispatch: AppDispatch = useDispatch();

    const filterByDateRange = (newCharger:any, startDate:any, endDate:any) => {
        return newCharger.filter(item => {
          const chargingStartTime = item?.charging_start_time;
          return chargingStartTime >= startDate && chargingStartTime <= endDate;
        });
      }

    useEffect(() => {
        const retrieveChargingHistory = async () => {
            const chargingHistoryData = await getChargingHistoryByUser();
            setChargingHistory(chargingHistoryData);
            const association = chargingHistoryData.map((ch: ChargerHistoryAttributes) => chargers.find((c) => c.uuid === ch.chargerId));
            setChargerAssociation(association);
        }

        retrieveChargingHistory(); 
    }, [] )

    const filterDate = () => {
        setModalVisible(!modalVisible)
        const filteredChargerList = filterByDateRange(newCharger, customDatePickerFirst, customDatePickerSecond);
        setFilteredCharger(filteredChargerList)
        notificationService.sendNotification('success', `Date Filter Applied`);
    }

    let dropdownSet;
    if(filteredCharger){
        dropdownSet = Array.isArray(filteredCharger) && filteredCharger?.length > 0 ?
        filteredCharger.map((charger: ChargerAttributes) => {
            return(
            {
                id: charger.uuid?.toString(),
                title: `${charger.uuid}: ${charger.name}`,
                charger: charger
            }
            );
            }) : [];
    }
    else{
        dropdownSet = Array.isArray(chargingHistory) && chargingHistory?.length > 0 ?
        chargingHistory?.map((chargerHistory: ChargerHistoryAttributes) => {
        return(
        {
            id: chargerHistory.userId?.toString(),
            title: `${chargerHistory.chargerId}: ${chargerHistory.total_charge_time}`,
            charger: chargerHistory
        }
        );
        }) : [];
    }
        
    const filteredData = dropdownSet.filter( item => {
        return  item.charger?.chargerId?.toLowerCase().startsWith(query?.toLowerCase()) || 
                item.charger?.name?.toLowerCase().startsWith(query?.toLowerCase()) || 
                item.charger?.city?.toLowerCase().startsWith(query?.toLowerCase())
    })

    const handleCancel = () => {
        setFilteredCharger()
        setModalVisible(!modalVisible)
        //notificationService.sendNotification('success', `Date Filter Removed`);
    }  

    let newCharger;

    if(uuidInfo){
        if(filteredData[0]?.charger === undefined){
            newCharger = []
        }
        else{
            if( filteredData.length > 0 ){
                let updatedCharger = []

                filteredData.forEach((chargerFetch, index)=>{
                    const fetchCharger = chargerFetch.charger
                    updatedCharger.push(fetchCharger)
                })
                newCharger = updatedCharger
            }
            else {
                newCharger = [filteredData[0]?.charger].map(v => ({...v}))
            }
        }
    }
    else {
        newCharger = chargingHistory?.map(v => ({...v}))
    }

    if(filteredCharger){
        newCharger = filteredCharger
    }
    

    newCharger?.forEach((charger, index) => {

            // Format data into dd/mm/yyy format
            const isoDateString = charger?.charging_start_time;
            const date = new Date(isoDateString);

            const day = String(date.getDate()).padStart(2, '0');
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const year = date.getFullYear();

            const formattedDate = `${month}/${day}/${year}`;
            charger.charging_start_time = formattedDate

            // Convert total charger time from ms to hours
            const milliseconds = charger?.total_charge_time;
            const hours = (milliseconds / (1000 * 60 * 60)).toFixed(2);

            charger.total_charge_time = hours;

            chargers?.forEach((chargerall, i) => {

                if(charger.chargerId === chargerall.uuid){

                    charger['name'] = chargerall.name;
                    charger['full_address'] = chargerall.full_address;
                    charger['charger_model'] = chargerall.charger_model;
                    charger['charger_type'] = chargerall.charger_type;
                    charger['charging_capacity'] = chargerall.charging_capacity;                   
                }
            })
    });

    const searchCharger = (searchString:any) => {
        setUuid(searchString)
        setQuery(searchString)
    }

    const handleFilter = () => {
        setModalVisible(!modalVisible)
    }

    const backSpace = () => {
        const updatedSearch = query.slice(0, -1)
        setQuery(updatedSearch)
    }

    const handleCustomDatePickerFirst = (date:any) => {
        SetCustomDatePickerFirst(date);
      };
    
    const handleCustomDatePickerSecond = (date:any) => {
        SetCustomDatePickerSecond(date);
    };

    const showReceipt = async (ch: ChargerHistoryAttributes, index: number) => {
        console.log(`Selected history index ${index}`, ch);
        //grab the charger object
        const chargerObj = await getSingleChargerData(ch.chargerId);
        navigation.navigate('ChargeSummary', {
            charger: chargerObj,
            startTime: ch.charging_start_time ? new Date(ch.charging_start_time) : null,
            endTime: ch.charging_end_time ? new Date(ch.charging_end_time) : null,
            orderId: ch.orderId
        })
        return 0;
    }

    useEffect(()=> {
        const getAllChargerAndHistory = async() => {
            const myChargerHistoriesData = await getChargingHistoryByUser(); //send blank values for retrieving all records
            const sortedChargingHistories = myChargerHistoriesData?.sort((a, b) => {
                return new Date(b.charging_start_time).getTime() - new Date(a.charging_start_time).getTime();
            });
            setChargingHistory(sortedChargingHistories);
        }

        getAllChargerAndHistory()
        //dispatch(getAllChargers())
    },[])
    
    return(
        <React.Fragment>
        <SafeAreaView style={styles.safeArea}>
            <View style={[styles.containerBgColor, {height: height - 65, paddingBottom: 10, flex: 1}]}>
                <ScrollView>
                    <View className='p-2'>
                        <View className='flex flex-row'>
                            <Ionicons name="search" size={22} color="white" style={styles.searchIcon}/>
                            <View style={styles.inputContainer}>
                                <TextInput
                                    placeholder='Search charger'
                                    style={styles.input}
                                    placeholderTextColor="#a0aec0"
                                    value={query}
                                    onChange={(e) => {
                                         searchCharger(e.nativeEvent.text);
                                       }}
                                    autoFocus={true}
                                />
                                <Pressable onPress={handleFilter}>
                                    <Image
                                    source={require('../../assets/searchFiltering.png')}
                                    style={styles.filterIcon}
                                    />
                                </Pressable>
                            </View>
                            <Pressable onPress={backSpace}>
                                <Ionicons name="backspace-outline" size={38} color="white"
                                style={styles.alignCrossIcon}/>
                            </Pressable>
                        </View>
                        {
                            chargingHistory?.map((ch,index) => {
                                return (
                                    <Pressable 
                                        onPress={() => showReceipt(ch, index)}
                                        key={`pressableItem${index}`}
                                    >
                                        <ChargeHistoryCard
                                            index={index}
                                            chargerHistory={ch}
                                            charger={chargerAssociation[index]}
                                            key={`historyCard${index}`}
                                        />
                                    </Pressable>
                                )
                            })
                        }
                    </View>
                </ScrollView>
            </View>

            {/* Modal */}
            <Modal
                animationType="slide"
                transparent={true}
                visible={modalVisible}
                onRequestClose={() => {
                    Alert.alert("Modal has been closed.");
                    setModalVisible(!modalVisible);
                }}
            >
                <View style={styles.centeredView}>
                    <View style={styles.modalView}>
                        <Text className="text-base font-bold text-center text-white">Filter settings</Text>
                        <Text className="px-3 py-2 text-sm font-medium text-center text-white">Select a date range to view your charger performance:</Text>
                        <View className="relative">
                            <View style={{ height: 80, justifyContent: 'center' }}>
                                    <>
                                    <Text className="mb-1 text-sm font-medium text-white">Select a start and end date:</Text>
                                    <View className="flex-row items-center justify-between">
                                        <DatePicker customDatePicker={{setCustomDatePicker: handleCustomDatePickerFirst}}/>
                                        <Text className="text-sm font-medium text-white">to</Text>
                                        <DatePicker customDatePicker={{setCustomDatePicker: handleCustomDatePickerSecond}}/>
                                    </View>
                                    </>
                            </View>
                            
                            
                        </View>

                        <View className="flex-row items-center justify-between px-2 py-1">
                            <Pressable onPress={handleCancel}>
                                <Text className="px-4 py-2 text-xs font-semibold text-white rounded-full">Cancel</Text>
                            </Pressable>
                            <Pressable onPress={filterDate}>
                                <Text 
                                    className="px-4 py-2 text-xs font-semibold text-white rounded-full"
                                    style={styles.BgColor}>Filter Chargers</Text>
                            </Pressable>
                        </View>

                    </View>
                </View>
            </Modal>

        </SafeAreaView>
        </React.Fragment>
    );
}

const styles = StyleSheet.create({
    safeArea: {
        flex: 1,
        backgroundColor: '#0B1E1D',
      },
    containerBgColor: {
        backgroundColor: '#1A1D21'
    },
    searchIcon: {
        backgroundColor: '#39BEB7',
        borderRadius: 50,
        paddingVertical: 12,
        paddingHorizontal: 13,
        marginRight: 8
    },
    inputContainer: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        backgroundColor: 'white',
        borderRadius: 25,
        paddingHorizontal: 20,
    },
    input: {
        flex: 1,
        paddingVertical: 10,
        outlineStyle: 'none'
      },
      filterIcon: {
        height: 19,
        width: 19,
        position: 'absolute',
        right: 10,
        top: -9
      },
    BgColor: {
        backgroundColor: '#51B868'
    },
    BgColor1: {
        backgroundColor: '#F97777'
    },
    textColor: {
        color: '#8D8E90'
    },
    textColor1: {
        color: '#E8E8E9',
    },
    textColor2: {
        color:'#39BEB7'
    },
    alignEllipses: {
        fontWeight: '500',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        maxWidth: 150,
    },
    centeredView: {
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
        marginTop: 22,
      },
    modalView: {
        margin: 20,
        backgroundColor: "#292D33",
        borderRadius: 30,
        padding: 10,
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 4,
        elevation: 5,
        width: 290,
        height: 250,
    },
    dropdownTrigger: {
        width: width <= 430 ? width*0.4 : 150,
    },
    dropdownContainer: {
        position: 'absolute',
        top: 30,
        left: 0,
        backgroundColor: '#fff',
        borderRadius: 5,
        marginTop: 2,
        width: width <= 430 ? width * 0.4 : 150,
        zIndex: 1000,
    },
    line: {
        borderBottomColor: "#636363",
        borderBottomWidth: 1,
        borderStyle: 'dashed',
        marginVertical: 20,
    },
    BgColor3: {
        backgroundColor: '#39BEB7'
    },
    BgColor4:{
        backgroundColor: '#636363'
    },
    backSpace: {
        backgroundColor: '#39BEB7',
        borderRadius: 50,
        paddingVertical: 13,
        paddingHorizontal: 15,
        marginRight: 8,
        marginLeft: 8,
        marginTop: 7
    },
    alignCrossIcon : {
        marginLeft:5, 
        marginTop:2
    }
})

export default TransactionHistory;