import { View, Text, Dimensions, StyleSheet } from "react-native";
import { SafeAreaView } from "react-native-safe-area-context";

const NoFooter = () => {
    const { width, height } = Dimensions.get("window");
    return (
        <SafeAreaView>
        <View
            style={[
              footerStyle.containerColor,
              footerStyle.container,
              { width: width < 430 ? width : 430 },
            ]}
        >
            <Text></Text>
        </View>
        </SafeAreaView>
    );
    };

export default NoFooter;

const footerStyle = StyleSheet.create({
  container: {
    marginLeft: "auto",
    marginRight: "auto",
    textAlign: "center",
    marginTop: 0,
    marginBottom: 0,
  },
  containerColor: {
    backgroundColor: "#FAFAFA",
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
  },
  textColor: {
    color: "#ffff",
  },
});
