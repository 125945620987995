import { UseSelector } from 'react-redux';
import { notificationService } from '../../../services/notification-service';

export const GetGoogleGeocode = async (address: string) => {
  try {
    //in 
    //geocode api: https://maps.googleapis.com/maps/api/geocode/json?address=
    //console.log('coordinate req:');
    //console.log(`https://maps.googleapis.com/maps/api/geocode/json?address=
    //${encodeURIComponent(address)}
    //&key=${process.env.REACT_NATIVE_GOOGLE_MAPS_API_KEY}`);
    const response = await fetch(
      //`https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(address)}&key=${process.env.REACT_NATIVE_GOOGLE_MAPS_API_KEY}`
      `https://app.meanderev.co:8803/api/geocode?address=${encodeURIComponent(address)}`
    );

    if (!response.ok) {
      const errorData = await response.json();
      notificationService.sendNotification('error','Unable to retrieve coordinates for the given address');
      return
    }

    const data = await response.json();
    console.log('coordinate response:', data.results[0]);
    return data.results[0];
  } catch (e: any) {
    console.error('Error fetching coordinates:', e);
  }
}

//return all geodata for our database
export const getAddressComponentsFromGeodata = (googleGeoData: any) => {
  const addressComponents = googleGeoData.address_components;

  const getComponent = (type: string) => {
    return addressComponents.find((component: any) =>
      component.types.includes(type)
    )?.long_name || '';
  };

  return {
    city: getComponent('locality'),
    state: getComponent('administrative_area_level_1'),
    country: getComponent('country'),
    street_name: getComponent('route'),
    street_number: getComponent('street_number'),
    zip_code: getComponent('postal_code')
  };
};

export const getCoordinatesFromGeodata = (googleGeoData: any) => {
    const location = googleGeoData.geometry.location;
    return {
      latitude: location.lat,
      longitude: location.lng,
    };
};

