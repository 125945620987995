import AdyenCheckout from '@adyen/adyen-web';
import { PrepareApiProcedure } from "../components/common/utils/prepare-api-procedure";
import { fetchDataFromAPI } from "../services/api-requests";
import { notificationService } from "../services/notification-service";
import { PaymentAttributes } from '../models/payment-model';
import { OrderAttributes } from '../models/order-model';

let dropin: any = null;
export const getPaymentMethods = async () => {
    try {
      const apiPaymentMethodsQuery = PrepareApiProcedure(`adyen/paymentMethods`, `GET`, 'payment', '')
      let paymentMethods = await fetchDataFromAPI(apiPaymentMethodsQuery)
      return paymentMethods
    } catch (e) {
        console.error(e);
        notificationService.sendNotification("error", `Error initiating payment: ${e}`);
        return null
    }
}
  
export const createPayment = async (data: any, orderId: string): Promise<PaymentAttributes | null> => {
    try {
      const apiPaymentMethodsQuery = PrepareApiProcedure(
        `payments`,
        `POST`,
        'payment',
        {
          paymentMethod: data.paymentMethod,
          orderId: orderId,
          storePaymentMethod: true
        }
        )
      let payment = await fetchDataFromAPI(apiPaymentMethodsQuery)
      return payment
  
    } catch (e) {
        console.error(e);
        notificationService.sendNotification("error", `Error creating payment: ${e}`);
        return null
    }
  }
  
export const getConfiguration = (
  paymentMethodsResponse: any,
  onSubmit: any
) => {
  console.log("creating adyen configuration");
    //const dispatch = useDispatch()
    return {
    paymentMethodsResponse: paymentMethodsResponse, // The `/paymentMethods` response from the server.
    clientKey: "test_VOPBT6PJCRHAFEYFARUSMKIS7YRAF5MV", // Web Drop-in versions before 3.10.1 use originKey instead of clientKey.
    locale: "en-US",
    environment: "test",
    analytics: {
      enabled: true // Set to false to not send analytics data to Adyen.
    },
    onSubmit: (state, dropin) => {
      onSubmit(state, dropin)
    },
    onAdditionalDetails: (state, dropin) => {
      // Your function calling your server to make a `/payments/details` request
  
      // TODO: None of this is currently functional
  
      makeDetailsCall(state.data)
        .then(response => {
          if (response.action) {
            // Drop-in handles the action object from the /payments response
            dropin.handleAction(response.action);
          } else {
            // Your function to show the final result to the shopper
            showFinalResult(response);
          }
        })
        .catch(error => {
          throw Error(error);
        });
    },
    paymentMethodsConfiguration: {
      card: { // Example optional configuration for Cards
        hasHolderName: true,
        holderNameRequired: true,
        enableStoreDetails: true,
        hideCVC: false, // Change this to true to hide the CVC field for stored cards
        name: 'Credit or debit card'
      }
    }
  }
};
  
export const openPaymentMethods = () => {
    dropin.mount('#dropin-container');
    return dropin
}

export const createAdyenDropin = async (
  paymentMethodsResponse: any,
  onSubmit: any
) => {
  const checkout = await AdyenCheckout(
    getConfiguration(paymentMethodsResponse, onSubmit)
  );
  dropin = checkout
  .create('dropin', {
  // Starting from version 4.0.0, Drop-in configuration only accepts props related to itself and cannot contain generic configuration like the onSubmit event.
      openFirstPaymentMethod:false,
  })
}

export const closeDropin = () => {
  if (dropin) {
    dropin.closeActivePaymentMethod();
  } else {
    console.error("Attempted to close null dropin!")
  }
}

export const getLastFour = async (orderId: string) => {
  const apiQuery = PrepareApiProcedure(
                  `payments/lastfour/${orderId}`, 
                  `GET`, 
                  'payment', '');
  const lastFour = await fetchDataFromAPI(apiQuery);
  return lastFour;
}