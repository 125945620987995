import React from "react";
import { View, Text, StyleSheet } from "react-native";
import { Button, Provider as PaperProvider } from 'react-native-paper';
import { DatePickerModal } from 'react-native-paper-dates';
import { SafeAreaProvider } from "react-native-safe-area-context";
import { getCurrentDate } from "./common/utils/date-time-conversions";

const formatDate = (date) => {
  const dd = String(date.getDate()).padStart(2, '0');
  const mm = String(date.getMonth() + 1).padStart(2, '0'); // January is 0!
  const yyyy = date.getFullYear();

  return `${mm}/${dd}/${yyyy}`;
};


export default function DatePicker({customDatePicker}:any) {
  const [date, setDate] = React.useState(undefined);
  const [open, setOpen] = React.useState(false);
  const [showDate, setShowDate] = React.useState('');

  const todayDate = getCurrentDate();

  const onDismissSingle = React.useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const onConfirmSingle = React.useCallback(
    (params) => {
      setOpen(false);
      setDate(params.date);
      const formattedDate = formatDate(params.date)
      customDatePicker.setCustomDatePicker(formattedDate);
      setShowDate(formattedDate);
      console.log('Date_picked:', params.date)
    },
    [setOpen, setDate]
  );

  return (
    <SafeAreaProvider>
      <View style={styles.container}>
        <Button onPress={() => setOpen(true)} uppercase={false} mode="outlined">
          {showDate !== '' ? showDate : todayDate}
        </Button>
        <DatePickerModal
          locale="en"
          mode="single"
          visible={open}
          onDismiss={onDismissSingle}
          date={date}
          onConfirm={onConfirmSingle}
        />
      </View>
    </SafeAreaProvider>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
});