import { StyleSheet, Dimensions } from 'react-native';

export const dropdownStylesNew = StyleSheet.create({
    container: {
      backgroundColor: '#234D4E',
      color: 'black',
      justifyContent: 'center',
    //   width: '100%',
      borderRadius: 25
    },
    dropdown: {
      height: 40,
      width: 100,
    //   borderColor: '#003849',
    //   borderBottomWidth: 1,
      paddingHorizontal: 8,
      color: 'white'
    },
    icon: {
      marginRight: 5,
    },
    label: {
      backgroundColor: 'white',
      left: 22,
      top: 8,
      zIndex: 999,
      paddingHorizontal: 8,
      fontSize: 14,
      fontWeight: "400",
      color: 'gray',
      width: '35%',
      textAlign: 'center'
    },
    placeholderStyle: {
      fontSize: 14,
      color:'white',
      fontWeight: "400",
    },
    selectedTextStyle: {
      fontSize: 14,
      color: 'white',
    },
    itemTextStyle: {
        fontSize: 16,
        color: 'black',
        fontWeight: "400",
    },
    iconStyle: {
      width: 20,
      height: 20,
    },
    inputSearchStyle: {
      height: 40,
      fontSize: 16,
    },
    hidden: {
      display: 'none'
    },
    visible: {
        display:'flex'
    }
  });