import { View, Text, Pressable, StyleSheet, TextInput, SafeAreaView, Dimensions, Image } from 'react-native';
import RBSheet from "react-native-raw-bottom-sheet";
import { UserSessionAttributes } from '../models/user-session-model';
import { useDispatch, useSelector } from 'react-redux';
import { userActions } from '../store/slices/user-slice';
import { storeUserSession } from '../services/user-session-service';
import { AppDispatch, RootState } from '../store';
import { clearSession } from '../store/actions/user-session';
import AsyncStorage from '@react-native-async-storage/async-storage';

const { width } = Dimensions.get('window');

const SignOutOptions = ({
    navigation, 
    refRBSheet,
    destination,
}) => {

    const userSession: UserSessionAttributes | undefined = useSelector((state: RootState) => state.user.session);

    const dispatch: AppDispatch = useDispatch();

    const handleLogout = async () => {
        dispatch(clearSession(userSession));

        await AsyncStorage.clear();

        const getToken = await AsyncStorage.getItem('token')
        
        navigation.navigate(destination, {
            token: getToken
        })
    }

    const CloseBottomSheet = () => {
        refRBSheet.current.close();
      }
    

    return (
        <SafeAreaView>
            <RBSheet
                    ref={refRBSheet}
                    height={210}
                    duration={250}
                    closeOnDragDown={true}
                    closeOnPressMask={true}
                    customStyles={{
                        container: {
                        borderRadius: 30,
                        backgroundColor: "#1A1D21",
                        width: width <= 430 ? width*0.95 : 400,
                        position: "relative",
                        display: "flex",
                        alignSelf: "center",
                        paddingBottom: 70,
                        marginBottom: 80
                        },
                    }}
                >   
                    <View>
                        <View style={{ flex:1, alignItems: 'center' }}>
                            <Image 
                                source={require('../../assets/headerBarIcon.png')} 
                                style={{ width: 55, height: 5, marginTop: 12 }}
                            />
                        </View>
                        <View className='p-4'>
                            <Text className='text-2xl font-bold text-center text-white'>Logout</Text>
                        
                            <View style={styles.line} className="my-4"/>
                            <Text className='text-base font-normal text-center' style={styles.textColor}>Are you sure you want to logout?</Text>
                                <View className="flex flex-row items-center justify-between px-6 py-3">
                                    <Pressable className="border border-white rounded-full" onPress={CloseBottomSheet}>
                                        <Text className="px-8 py-3 text-xs font-semibold text-white rounded-full">Cancel</Text>
                                    </Pressable>
                                    <Pressable onPress={handleLogout}>
                                        <Text 
                                            className="px-8 py-3 text-xs font-semibold text-white rounded-full"
                                            style={styles.bgColor}>Yes, Logout</Text>
                                    </Pressable>
                                </View>
                        </View>
                    </View>
                </RBSheet>
        </SafeAreaView>
    );
};

const styles = StyleSheet.create({
    containerBgColor: {
        backgroundColor: '#1A1D21'
    },
    bgColor: {
        backgroundColor: '#F75555'
    },
    textColor: {
        color: '#8D8E90'
    },
    line: {
        borderBottomColor: "#636363",
        borderBottomWidth: 1,
        borderStyle: 'dashed',
    },
})

export default SignOutOptions;
