export const routeTitleMap: { [key: string]: { title: string; offset: number } } = {
    WelcomeScreen: { title: "Welcome To MeanderEV!", offset: 0 },
    LoginScreen: { title: "Login to Your Account", offset: 10 },
    ChargerScan: { title: "Scan a Charger", offset: 30 },
    ChargerStation: { title: "Charger Station Details", offset: 40 },
    ChargerSelection: { title: "Select a Charger", offset: 35 },
    ChargerValidation: { title: "Validate Charger Details", offset: 50 },
    AddModifyCharger: { title: "Add or Modify Charger", offset: 35 },
    MyChargers: { title: "My Chargers", offset: 35 },
    DatePicker: { title: "Select Date and Time", offset: 30 },
    ChargingScreen: { title: "Charging in Progress", offset: 60 },
    ChargeSummary: { title: "Charging Summary", offset: 45 },
    MyAccount: { title: "My Account Overview", offset: 0 },
    AccountInfo: { title: "Account Information", offset: 350 },
    TransactionHistory: { title: "Transaction History", offset: 35 },
    CreateAccount: { title: "Create a New Account", offset: 5 },
    LoginWithAccount: { title: "Login with Existing Account", offset: 10 },
    ManageChargers: { title: "Manage Chargers", offset: 40 },
    undefined: { title: "MeanderEV", offset: 0 }, // Default for undefined routes
  };