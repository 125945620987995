import React, { useState } from 'react';
import { QrReader } from 'react-qr-reader'

import { SafeAreaView, View,Text, ScrollView, StyleSheet, Dimensions, TextInput, Image } from 'react-native';
import SearchCharger from './SearchCharger';
import { notificationService } from '../services/notification-service';
import { AppDispatch } from '../store';
import { useDispatch } from 'react-redux';
import { chargerQrScan } from '../store/actions/charging-actions';

const { width, height } = Dimensions.get('window');

const extractSerialNumberFromQr = (qrCodeText: string): string | null => {
    const regex = /^https:\/\/app\.meanderev\.co\/chargerscan\/(.+)$/;
    const match = qrCodeText.match(regex);
    
    return match ? match[1] : null; // Returns the serialNumber or null if no match
};

const QRScanner = ({navigation, myLocation}) => {
    const [qrResult, setQrResult] = useState('');
    const dispatch:AppDispatch = useDispatch();

    // Function to handle QR code scan result
    const handleScan = (result:any, error:any, codeReader:any) => {
        if (result) {
            const serialNumber = extractSerialNumberFromQr(result.getText());
            if(serialNumber === null) {
                notificationService.sendNotification("error", "Not a valid MeanderEV QR Code!");
            } else {
                //get the charger data and select the charger
                dispatch(chargerQrScan(serialNumber));
                navigation.navigate('ChargerStation', {searchCharger: true});
            }
        } 
        // else {
        //     console.error('An error occurred while scanning:', error);
        // }
    };

    // Function to handle scan error
    // const handleError = (error:any) => {
    //     console.error('An error occurred while scanning:', error);
    // };
    

    return(
        <SafeAreaView>
            <View style={{height: height - 65}}>
                    <View>
                        <Text className='text-lg font-bold text-center text-white'>Scan QR Code</Text>
                        <Text className='text-lg font-normal text-center' style={{color: '#8D8E90'}}>Please point the camera at the QR Code</Text>
                        <QrReader
                            constraints={{advanced: [{ facingMode: 'environment' }]}} // Specify media track constraints
                            onResult={handleScan} // Callback function for QR code scan result
                            //error={handleError} // Callback function for scan error
                            scanDelay={300} // Delay between scans in milliseconds
                            videoId="qr-video" // ID of the video element
                            className="qr-scanner" // Optional class name for styling
                            containerStyle={{ width: '100%' }} // Style for the container
                            videoContainerStyle={{ width: '100%' }} // Style for the video container
                            videoStyle={{ width: '100%' }} // Style for the video
                        />
                    </View>
            </View>
        </SafeAreaView>
    )
}

const styles = StyleSheet.create({
   
})

export default QRScanner