import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  SafeAreaView,
  View,
  Text,
  Dimensions,
  StyleSheet,
  ScrollView,
  Pressable,
  Platform
} from "react-native";
import { TabView, SceneMap, TabBar } from 'react-native-tab-view';
import FindChargerMap from "./FindChargerMap";
import SearchCharger from "./SearchCharger";
import QRScanner from "./QRScanner";
import * as Location from 'expo-location';
import { notificationService } from "../services/notification-service";
import { useDispatch, useSelector } from "react-redux";
import { getAllChargers, getMyDistanceFromCharger } from "../store/actions/charging-actions";
import { NativeStackNavigationProp, NativeStackScreenProps } from "@react-navigation/native-stack";
import { RootStackParamList } from "../components/MeanderStack";
import { RouteProp } from "@react-navigation/native";
import { PanGestureHandler, State } from 'react-native-gesture-handler';
import { AppDispatch, RootState } from "../store";
import { getUserLocation } from "../components/common/utils/get-user-location";
import { ChargerAttributes } from "../models/charger-model";

type Props = NativeStackScreenProps<RootStackParamList, 'ChargerSelection'>;

export const ChargerSelection: React.FC<Props> = ({ navigation, route }) => {
  const [index, setIndex] = useState(0);
  const [query, setQuery] = useState('');
  const [chargersWithDistance, setChargersWithDistance] = useState<ChargerAttributes[]>([]);
  const [myLocation, setMyLocation] = React.useState<Location.LocationObjectCoords | null>(null);
  const [swipeEnabled, setSwipeEnabled] = useState<boolean>(true);

  const routes = [
    { key: 'Map', title: 'Map' },
    { key: 'Search', title: 'Search' },
    { key: 'ScanQr', title: 'Scan QR' },
  ];
  
  const dispatch: AppDispatch = useDispatch();

  const chargerList: ChargerAttributes[] = useSelector(
    (state: RootState) => state.chargers.chargers
  ) as ChargerAttributes[];

  const { width, height } = Dimensions.get("window");

  useEffect(() => {
    const requestLocation = async () => {
      const userLocation = await getUserLocation();
      setMyLocation(userLocation);
    };

    const defaultIndex = routes.findIndex((idx) => idx.key === route.params.tabSelect);
    setIndex(defaultIndex);

    requestLocation();
    dispatch(getAllChargers());
  }, [dispatch]);

  useEffect(() => {
    if (!myLocation || chargerList.length === 0) return;

    // Calculate distances and sort chargers by distance
    const calculateDistances = async () => {
      // Ensure that we are mapping each charger with its index to preserve order.
      const promises = chargerList.map(async (charger, index) => {
        const distance = await getMyDistanceFromCharger(myLocation, charger.uuid);
        return { ...charger, totalDistance: distance, originalIndex: index };
      });

      const chargersWithDistances = await Promise.all(promises);
      // Sort by distance first and fallback to original order if needed.
      const sortedChargers = chargersWithDistances.sort((a, b) => a.totalDistance - b.totalDistance || a.originalIndex - b.originalIndex);
      setChargersWithDistance(sortedChargers);
    };

    calculateDistances();
  }, [chargerList, myLocation]);

  const dropdownSet = Array.isArray(chargersWithDistance) && chargersWithDistance.length > 0 ?
                    chargersWithDistance.map((charger: ChargerAttributes) => {
        return(
            {
                id: charger.uuid?.toString(),
                title: `${charger.unit_serial}: ${charger.name}`,
                charger: charger
            }
        );
    }) : [];

  React.useEffect(() => {
    const requestLocation = async () => {
        const userLocation = await getUserLocation();
        setMyLocation(userLocation);
    };

    const defaultIndex = routes.findIndex((idx) => idx.key === route.params.tabSelect)
    setIndex(defaultIndex);

    requestLocation();
    dispatch(getAllChargers());
  }, []);

  React.useEffect(() => {
    if (chargerList.length === 0) return;

    // Your asynchronous code to fetch chargers and calculate distances
    let chargerDistance: any = [];
    const promises = chargerList?.map(async (chargersInfo) => {
        let myDistance = 0;
        if(myLocation){
          myDistance = await getMyDistanceFromCharger(myLocation, chargersInfo.uuid);
        }

        chargerDistance.push(myDistance);
        return myDistance;
    });

    Promise.all(promises)
        .then((chargers) => {
            const addDistanceToChargers = chargerList.map((charger: ChargerAttributes, index) => {
                return {
                    ...charger,
                    totalDistance: chargerDistance[index]
                };
            });
            const sortByDistance = addDistanceToChargers.sort((a, b) => a.totalDistance - b.totalDistance);
            setChargersWithDistance(sortByDistance);
        })
        .catch(error => {
            console.error("Error:", error);
        });
  }, [chargerList, myLocation]);

  const renderTabBar = (props:any) => (
    <TabBar
      {...props}
      indicatorStyle={{ backgroundColor: 'white' }}
      style={{ backgroundColor: '#1A1D21', flexDirection: 'row', justifyContent: 'space-around' }}
      renderLabel={({ route, focused, color }) => (
        <Pressable
          style={[styles.tabButton, focused && styles.tabButtonFocused]}
          onPress={() => {
            // Optionally, add any functionality you want when the tab is pressed
            setIndex(props.navigationState.routes.findIndex((r:any) => r.key === route.key));
          }}
        >
          <Text style={{ color: focused ? 'white' : '#8D8E90', fontSize: 14, fontWeight: '700' }}>{route.title}</Text>
        </Pressable>
      )}
    />
  );

  const handleMapInteraction = (isInteracting: boolean): void => {
    setSwipeEnabled(!isInteracting);
  };

  const MapRoute = () => {
    return(
      <View style={[ styles.containerBgColor, {marginBottom: 20}]}>
        {myLocation && 
        <FindChargerMap 
          navigation={navigation}
          myLocation={myLocation}
          onMapInteraction={handleMapInteraction}
          chargersWithDistance={chargersWithDistance}
        />}
      </View>
    )
  }

  const SearchRoute = () => {
    return (
      <View style={[styles.scene, styles.containerBgColor]}>
          {myLocation && 
            <SearchCharger 
              navigation={navigation}
              myLocation={myLocation}
              chargersWithDistance={chargersWithDistance}
            />
          }
      </View>
    )
  };

  const ScanQrRoute = () => {
    if(index === 2){
      return(
        <View style={[styles.scene, styles.containerBgColor]}>
            <QRScanner 
              navigation={navigation}
              myLocation={myLocation}
            />
        </View>
      )
    } else {
      return null;
    }
  };

  return (
    <SafeAreaView>
        <View style={[styles.containerBgColor, { height: height - 65, paddingBottom: 10, 
            width: width < 430 ? width : 430,
             }]}>
            <TabView
                    navigationState={{index, routes}}
                    renderScene={SceneMap({
                      Map: MapRoute,
                      Search: SearchRoute,
                      ScanQr: ScanQrRoute
                    })}
                    onIndexChange={setIndex}
                    initialLayout={{ width: Dimensions.get('window').width }}
                    renderTabBar={renderTabBar}
                    swipeEnabled={swipeEnabled}
                />
        </View>
    </SafeAreaView>
  );
}

export default ChargerSelection;

const styles = StyleSheet.create({
 containerBgColor: {
    backgroundColor: '#1A1D21'
 },
 scene: {
  flex: 1
 },
  tabButton: {
    paddingVertical: 10,
    paddingHorizontal: 25,
    borderRadius: 20,
  },
  tabButtonFocused: {
    backgroundColor: '#39BEB7',
  },
  textColor: {
    color: '#8D8E90'
  }
});
