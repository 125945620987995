import { StyleSheet, Dimensions } from 'react-native';


export const modalStyles = StyleSheet.create({
    modalContainer: {
      flex: 1,
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      justifyContent: 'flex-end',
      //backgroundColor: "rgba(0, 53, 71, 1)"
    },
    modalContent: {
      //flex: 1,
      backgroundColor: 'white',
      borderTopLeftRadius: 20,
      borderTopRightRadius: 20,
      paddingHorizontal: 16,
      paddingTop: 16,
      //paddingBottom: 24, // Adjust as needed
      height: '80%', // Adjust the height as needed
      //zIndex: 999
    },
    content: {
      paddingVertical: 10,
    },
    searchBarContainer: {
      flex: 0,
      width: '90%',
      marginBottom: 16,
      justifyContent: 'center',
      zIndex: 100,
      marginTop: 15,
    },
    searchInput: {
      height: 40,
      borderColor: 'gray',
      borderWidth: 1,
      paddingHorizontal: 8,
    },
    additionalComponents: {
      marginBottom: 16,
    },
    buttonContainer: {
      flexDirection: 'row',
      justifyContent: 'space-around',
    },
    container: { 
        width: '90%'
    },
    dropdown: {
        width: '90%',
        marginTop: 10,
        flex: 0,
        marginBottom: 5,
        alignItems: 'center'
    },
    textFieldContainer: {
      marginBottom: 0,
    },
    textFieldStyle: {
      width: '100%',
      height: 50,
      paddingHorizontal: 10,
      margin: 5,
    },
    passwordTextFieldStyle: {
      width: '100%',
      height: 50,
      paddingHorizontal: 10,
      margin: 0,
  },
  scrollViewContainer: {
    flexGrow: 1,
    justifyContent: 'center',
  },
  });