import * as Location from 'expo-location';
import {
    Platform
  } from "react-native";
import { notificationService } from '../../../services/notification-service';

export const getUserLocation = async () => {
    try {
        const { status } = await Location.requestForegroundPermissionsAsync();

        if (status === 'granted') {
            const location = await Location.getCurrentPositionAsync({});
            return location.coords;
        } else {
            Platform.OS === 'web' ? '' :
            notificationService.sendNotification('error', 'Location permission denied');
            return null;
        }
    } catch (error) {
        notificationService.sendNotification('error', `Error requesting location permission: ${error}`);
        return null;
    }
};
