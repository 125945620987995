import React, { useCallback, useMemo, useState } from 'react';

import { SafeAreaView, View,Text, ScrollView, StyleSheet, Dimensions, TextInput, Image, Pressable } from 'react-native';
import { Ionicons } from '@expo/vector-icons';
import { FontAwesome6 } from '@expo/vector-icons';
import { MaterialIcons } from '@expo/vector-icons';
import { useDispatch, useSelector } from 'react-redux';
import { ChargerAttributes } from '../models/charger-model';
import { RootState } from '../store';
import { getMyDistanceFromCharger, getMyDrivingTime } from '../store/actions/charging-actions';
import { chargerActions } from '../store/slices/charger-slice';
import { Button } from 'react-native-paper';
import { AppDispatch } from '../store';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { RootStackParamList } from '../components/MeanderStack';
import * as Location from 'expo-location';

interface SearchChargerProps {
    navigation: NativeStackNavigationProp<RootStackParamList, 'SearchCharger'>;
    myLocation: Location.LocationObjectCoords;
    chargersWithDistance: ChargerAttributes[];
  }

const SearchCharger: React.FC<SearchChargerProps>  = ({ navigation, myLocation, chargersWithDistance }) => {
    const { height } = Dimensions.get('window');
    const [currentPage, setCurrentPage] = useState<number>(1);
    const itemsPerPage = 10;
    const [query, setQuery] = useState('');

    const [uuidInfo, setUuid] = useState<String>('');

    const handlePageChange = (newPage: number) => {
        setCurrentPage(newPage);
    };

    const handleSearchQuery = useCallback((searchTerm: string) => {
        setQuery(searchTerm);
      }, []);

    // Memoize filtered chargers based on the query
    const searchFilteredChargers = useMemo(() => {
        return chargersWithDistance.filter((charger) => {
            return (
                charger.unit_serial.toLowerCase().includes(query.toLowerCase()) ||
                charger.name.toLowerCase().includes(query.toLowerCase()) ||
                charger.city.toLowerCase().includes(query.toLowerCase())
            );
        });
    }, [chargersWithDistance, query]);

    // Calculate the paginated data
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = searchFilteredChargers.slice(indexOfFirstItem, indexOfLastItem);

    const dispatch: AppDispatch = useDispatch();

    React.useEffect(() => {
        //console.log();
        //dispatch(getAllChargers());
    }, []);

    const handleSelectCharger = async (charger: any) => {
        
        const myTime = await getMyDrivingTime(myLocation, charger.uuid);

        dispatch(chargerActions.updateSelectedCharger({selectedCharger: charger}));
        navigation.navigate("ChargerStation", {
            myDistanceFromCharger: charger.totalDistance,
            myTimeFromCharger: myTime,
        });
    }

    return (
        <SafeAreaView style={[styles.safeArea, { height: height * 0.7 }]}>
          <View style={[styles.containerBgColor, { flex: 1 }]}>
            <View style={{ padding: 16 }}>
              <View style={styles.searchContainer}>
                <Ionicons name="search" size={22} color="white" style={styles.searchIcon} />
                <TextInput
                  placeholder='Search charger by name, city, or serial #'
                  style={styles.input}
                  placeholderTextColor="#a0aec0"
                  value={query}
                  onChangeText={handleSearchQuery}
                />
                <Pressable onPress={() => handleSearchQuery('')}>
                  <MaterialIcons name="highlight-remove" size={38} color="black" style={styles.clearIcon} />
                </Pressable>
              </View>
    
              {/* Card Render */}
              <ScrollView>
                {currentItems?.map((data, index) => (
                  <View className='mt-4 rounded-lg' key={index} style={styles.cardContainer}>
                    <Pressable onPress={() => handleSelectCharger(data)}>
                    <View className='flex flex-row items-center justify-between p-3'>
                      <View className='flex flex-row items-center gap-4'>
                        <Image
                          source={require('../../assets/chargerStation.png')}
                          style={styles.chargerImage}
                        />
                        <View>
                          <Text className='text-base font-bold text-white' style={styles.alignEllipses}>
                            {data?.name ? data?.name : 'Unknown'}
                          </Text>
                          <Text style={[styles.textColor, styles.alignEllipses]} className='text-xs font-medium'>
                            {`${data?.street_name ? data?.street_name : "Street"} ${data?.city ? data?.city : "City"} ${data?.state ? data?.state : "State"} ${data?.country ? data?.country : "Country"}`}
                          </Text>
                          <Text style={styles.textColor} className='text-xs font-medium'>{data?.location}</Text>
                        </View>
                      </View>
                        <View className='flex flex-row items-center gap-1'>
                          <FontAwesome6 name="location-dot" size={16} color='#8D8E90' />
                          <Text style={styles.textColor}>{data?.totalDistance} miles</Text>
                          <MaterialIcons name="keyboard-arrow-right" size={24} color="white" />
                        </View>
                    </View>
    
                    {/* Charger Type and Status */}
                    <View className='flex flex-row items-center justify-between p-3'>
                      <View className='flex flex-row items-center gap-3'>
                        <Image
                          source={require('../../assets/chargerType.png')}
                          style={styles.chargerTypeImage}
                        />
                        <View>
                          <Text className='text-xs font-normal text-white' style={styles.textColor1}>
                            {`${data?.charger_model} - ${data?.charger_type} Charger`}
                          </Text>
                          <View className='flex flex-row items-center gap-1'>
                            <Text className='text-lg font-semibold text-white'>
                              {`$ ${data?.price_hourly_peak}/hr (pk)`}
                            </Text>
                          </View>
                        </View>
                      </View>
    
                      <View>
                        {data?.isOccupied === 0 && (data.status === 'complete' || data.status === null) ? (
                          <Text
                            style={styles.availableStatus}
                            className='w-full px-6 py-2 text-white rounded-full'
                            onPress={() => handleSelectCharger(data)}
                          >
                            Available
                          </Text>
                        ) : (
                          <Text style={styles.unavailableStatus} className='px-4 py-2 text-white rounded-full'>
                            Unavailable
                          </Text>
                        )}
                      </View>
                    </View>
                    </Pressable>
                  </View>
                ))}
              </ScrollView>
            </View>
    
            {/* Pagination */}
            <View style={styles.paginationContainer}>
              {Array.from({ length: Math.ceil(searchFilteredChargers.length / itemsPerPage) }).map((_, i) => (
                <Pressable key={i} onPress={() => handlePageChange(i + 1)}>
                  <Text style={currentPage === i + 1 ? styles.activePage : styles.inactivePage}>{i + 1}</Text>
                </Pressable>
              ))}
            </View>
          </View>
        </SafeAreaView>
      );
    };
    
    const styles = StyleSheet.create({
      safeArea: {
        flex: 1,
        backgroundColor: '#1A1D21',
      },
      containerBgColor: {
        backgroundColor: '#1A1D21',
      },
      searchContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        backgroundColor: 'white',
        borderRadius: 25,
        paddingHorizontal: 20,
      },
      searchIcon: {
        backgroundColor: '#39BEB7',
        borderRadius: 50,
        paddingVertical: 12,
        paddingHorizontal: 13,
        marginRight: 8,
      },
      input: {
        flex: 1,
        paddingVertical: 10,
      },
      clearIcon: {
        marginLeft: 5,
        marginTop: 2,
      },
      cardContainer: {
        backgroundColor: '#292D33',
        marginVertical: 10,
        borderRadius: 10,
        padding: 10,
      },
      chargerImage: {
        width: 50,
        height: 50,
      },
      chargerTypeImage: {
        width: 33,
        height: 33,
      },
      alignEllipses: {
        fontWeight: '500',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        maxWidth: 150,
      },
      textColor: {
        color: '#8D8E90',
      },
      textColor1: {
        color: '#E8E8E9',
      },
      availableStatus: {
        backgroundColor: '#51B868',
        borderRadius: 20,
        paddingVertical: 8,
        paddingHorizontal: 16,
        color: 'white',
      },
      unavailableStatus: {
        backgroundColor: '#F97777',
        borderRadius: 20,
        paddingVertical: 8,
        paddingHorizontal: 16,
        color: 'white',
      },
      paginationContainer: {
        flexDirection: 'row',
        justifyContent: 'center',
        padding: 5,
      },
      activePage: {
        color: 'white',
        padding: 5,
        backgroundColor: '#39BEB7',
        borderRadius: 5,
        marginHorizontal: 3,
      },
      inactivePage: {
        color: 'white',
        padding: 5,
        marginHorizontal: 3,
      },
    });

export default SearchCharger;