import { StyleSheet, Dimensions } from 'react-native';

export const styles = StyleSheet.create({
  ChargerScreen: {
  flex: 1,
  //height: Dimensions.get('window').height,
  //width: Dimensions.get('window').width,
  backgroundColor: "rgba(0, 53, 71, 1)",
  justifyContent: 'flex-start',
  alignItems: 'center',
  flexDirection: 'column',
  margin: 0
},
textFieldStyle: {
    width: '100%',
    height: 50,
    paddingHorizontal: 10,
    margin: 10
},
centeredView: {
  flex: 1,
  justifyContent: 'flex-end',
  //alignItems: 'center',
  marginTop: 22,
},
modalView: {
  margin: 20,
  backgroundColor: 'white',
  justifyContent: 'flex-end',
  borderRadius: 20,
  padding: 35,
  alignItems: 'center',
  shadowColor: '#000',
  shadowOffset: {
    width: 0,
    height: 2,
  },
  shadowOpacity: 0.25,
  shadowRadius: 4,
  elevation: 5,
},
modalContainer: {
  flex:1,
  //alignItems: 'center',
  paddingTop: 10,
  width: '100%',
  justifyContent: 'flex-end',
},
listItem: {
  padding: 10,
  borderBottomWidth: 1,
  borderBottomColor: 'gray',
},
modalText: {
  marginBottom: 15,
  textAlign: 'center',
},
scrollContainer:{
  paddingHorizontal: 16,
  paddingBottom: 16,
},
searchBarContainer: {
  width: '100%',
  flex:1,
  justifyContent:"center",
  alignItems: "center",
  position: "flex",
  zIndex:1,
  top:0,
},
chargerMain: {
  position: "absolute",
  flexShrink: 0,
  top: 48,
  bottom: 0,
  left: 0,
  right: 0,
  backgroundColor: "rgba(0, 53, 71, 1)",
  alignItems: "flex-start",
  rowGap: 0
},
title: {
    fontSize: 36,
    textAlign:"center",
    flexShrink: 1,
    marginTop: 10,
    marginBottom: 20,
    color: "rgba(57, 190, 183, 1)",
},
slide: {
  backgroundColor:'rgba(57, 190, 183, 1)',
  borderRadius: 50,
  height: 250,
  padding: 50,
  marginLeft: 50,
  marginRight: 50,
},
subtitle: {
    fontSize: 24,
    textAlign:"center",
    flexShrink: 1,
    marginBottom: 10,
    marginTop: 10,
    color: "rgba(57, 190, 183, 1)",
},
buttonContainer: {
    flex: 0,
    justifyContent:'center',
    alignItems:'center',
},
button: {
    width: 200,
    marginTop: 20,
    backgroundColor: "rgba(57, 190, 183, 1)",
    padding: 15,
    borderRadius: 20,
    textAlign: 'center',
},
inlineButtonContainer: {
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: 16,
},
inlineButton: {
  width: 125,
  marginTop: 0,
  marginLeft: 7,
  backgroundColor: "rgba(57, 190, 183, 1)",
  padding: 5,
  borderRadius: 5,
  textAlign: 'center',
},
inlineText: {
  fontSize: 14,
  fontWeight: 'bold',
  color: "rgba(57, 190, 183, 1)",
  fontFamily: "Arial",
},
buttonClose: {
  backgroundColor: '#2196F3',
},
buttonText: {
    textAlign: 'center',
    color: 'white',
},
separator: {
    marginVertical: 8,
    borderBottomColor: '#737373',
    borderBottomWidth: StyleSheet.hairlineWidth,
},
  titlex: {
  position: "center",
  flexShrink: 0,
  top: 48,
  right: 24,
  left: 24,
  height: 48,
  textAlign: "center",
  color: "rgba(57, 190, 183, 1)",
  fontFamily: "Arial",
  fontSize: 34,
  fontWeight: "400",
  letterSpacing: 0.25,
  lineHeight: 40
},
  subtitlex: {
  position: "absolute",
  flexShrink: 0,
  top: 109,
  right: 57,
  left: 57,
  height: 24,
  textAlign: "center",
  color: "rgba(57, 190, 183, 1)",
  fontFamily: "Arial",
  fontSize: 16,
  fontWeight: "400",
  letterSpacing: 0.15000000596046448,
  lineHeight: 24
},
  textFieldOutlined: {
  position: "absolute",
  flexShrink: 0,
  top: 195,
  height: 56,
  left: 24,
  right: 24,
  alignItems: "flex-start",
  rowGap: 0
},
  outline: {
  position: "absolute",
  flexShrink: 0,
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  borderWidth: 1,
  borderColor: "rgba(0, 0, 0, 0.38)",
  borderRadius: 4
},
  account: {
  position: "absolute",
  flexShrink: 0,
  top: 16,
  bottom: 16,
  right: 16,
  width: 24,
  alignItems: "flex-start",
  rowGap: 0
},
  vector: {
  position: "absolute",
  flexShrink: 0,
  top: 2,
  right: 2,
  bottom: 2,
  left: 2,
  overflow: "visible"
},
  placeholdertext: {
  position: "absolute",
  flexShrink: 0,
  top: 4,
  right: 16,
  bottom: 4,
  left: 16,
  textAlign: "left",
  color: "rgba(0, 0, 0, 0.38)",
  fontFamily: "Arial",
  fontSize: 16,
  fontWeight: "400",
  letterSpacing: 0.4399999976158142,
  lineHeight: 28
},
  container: {
    flex: 1,
  },
  map: {
    width: 400,
    height: 400,
    borderRadius:15
  },
  _textFieldOutlined: {
  position: "absolute",
  flexShrink: 0,
  top: 259,
  height: 48,
  left: 24,
  right: 24,
  alignItems: "flex-start",
  rowGap: 0
},
  _outline: {
  position: "absolute",
  flexShrink: 0,
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  borderWidth: 1,
  borderColor: "rgba(0, 0, 0, 0.38)",
  borderRadius: 4
},
  _placeholdertext: {
  position: "absolute",
  flexShrink: 0,
  top: 4,
  right: 16,
  bottom: 4,
  left: 16,
  textAlign: "left",
  color: "rgba(0, 0, 0, 0.38)",
  fontFamily: "Arial",
  fontSize: 16,
  fontWeight: "400",
  letterSpacing: 0.4399999976158142,
  lineHeight: 28
},
  checkboxLabel: {
  position: "absolute",
  flexShrink: 0,
  top: 323,
  left: 24,
  right: 63,
  flexDirection: "row",
  alignItems: "flex-start",
  columnGap: 8
},
  checkbox: {
  flexShrink: 0,
  height: 40,
  width: 40,
  alignItems: "flex-start",
  rowGap: 0
},
  unchecked: {
  position: "absolute",
  flexShrink: 0,
  top: 11,
  left: 11,
  width: 18,
  height: 18,
  overflow: "visible"
},
  label: {
  flexShrink: 0,
  width: 196,
  height: 40,
  textAlign: "left",
  color: "rgba(0, 0, 0, 0.87)",
  fontFamily: "Arial",
  fontSize: 16,
  fontWeight: "400",
  letterSpacing: 0.4399999976158142,
  lineHeight: 28
},
  containedButton: {
  position: "absolute",
  flexShrink: 0,
  top: 390,
  height: 48,
  left: 24,
  right: 24,
  backgroundColor: "rgba(21, 84, 246, 1)",
  shadowOffset: {
      width: 0,
      height: 2
  },
  shadowRadius: 2,
  shadowColor: "rgb(0, 0, 0)",
  shadowOpacity: 0.24,
  alignItems: "flex-start",
  rowGap: 0,
  paddingVertical: 0,
  paddingHorizontal: 16,
  borderRadius: 4
},
  _label: {
  position: "absolute",
  flexShrink: 0,
  top: 16,
  right: 16,
  bottom: 16,
  left: 16,
  textAlign: "center",
  color: "rgba(255, 255, 255, 1)",
  fontFamily: "Arial",
  fontSize: 14,
  fontWeight: "500",
  letterSpacing: 1,
  lineHeight: 16
},
  caption: {
  position: "absolute",
  flexShrink: 0,
  right: 78,
  bottom: 11,
  left: 79,
  height: 16,
  textAlign: "center",
  color: "rgba(0, 0, 0, 0.6)",
  fontFamily: "Arial",
  fontSize: 12,
  fontWeight: "400",
  letterSpacing: 0.4000000059604645,
  lineHeight: 16
},
})